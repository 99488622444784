
.scrolllink{
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #16171A;
    &:hover{
        font-weight: 700;
        color: #16171A;
    }
}

a{
    color: #16171A !important;
}

.is-current{
    color: #16171A !important;
    font-weight: 800 !important; 
    font-size: 17px;
}

@media screen and (min-width:900px) and (max-width: 1250px){
    .scrolllink{
        font-size: 9px;
    }

    .is-current{
        font-size: 12px;
    }

}
