.casestudyMaster{
    background-color: #F5F5F5;
    margin: 0px 14vw;
    height: calc(100vh);
    width:72vw;
    color: #16171A;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 120px;
}

.case-main-head{
    padding: 20px 6vw;
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    color: #16171A;
    display: flex;
    justify-content: center;
    text-align: center;
}

.mob-case-main-head{
    display: none;
}

.all-categories{
    overflow-x: hidden;
    margin: 20px 14vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 511vw;
}

.each-category{
    cursor: pointer;
    margin: 10px 1vw;
    width: 12.5vw;
    height: 40px;
    border: 0.5px solid #16171A;
    color: #16171A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    border-radius: 15px;
    &:hover{
        background-color: #16171Ac6;
        color: #FFFFFF;
    }
}

.each-category-selected{
    cursor: pointer;
    margin: 10px 1vw;
    width: 12.5vw;
    height: 40px;
    border: 0.5px solid #16171A;
    background-color: #16171A;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 13px;
    border-radius: 15px;
}

.all-brands{
    overflow-x: hidden;
    margin: 20px 3vw;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.each-brand{
    padding: 20px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px 1vw;
    width: 18vw;
    height: 300px;
    background-color: #FFFFFF;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    z-index: 0;
    &:hover{
        transform: scale(1.05);
        transition: all ease 0.5s;
    }
}

.each-brand::after {
    background: #16171A;
    content: "";
    height: 1220px;
    left: -655px;
    opacity: 1;
    position: absolute;
    top: -150px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 60px;
    z-index: -10;
  }
  
  .each-brand:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

.brand-icon{
    margin: 20px 0px;
    height: 90px;
    width: 90px;
}

.brand-name{
    margin: 10px 0px;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    color: #16171A;
}

.brand-desc{
    margin: 5px 0px;
    font-weight: 300;
    font-size: 13px;
    line-height: 27px;
    color: #16171A;
}


@media only screen and (min-width: 0px) and (max-width: 900px){
    .mob-sticky{
        background-color: #F5F5F5;
        z-index: 1;
        position: sticky;
        top: 0;
    }
    .casestudyMaster{
        width: 100vw;
        margin: 0px;
        padding: 9vw;
    }
    .case-main-head{
        display: none;
    }
    .mob-case-main-head{
        padding: 20px 0px;
        font-weight: 700;
        font-size: 30px;
        line-height: 75px;
        color: #16171A;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .all-categories{
        overflow-x: scroll;
        display: -webkit-inline-box;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 20px 6vw;
        width: 70vw;
    }
    .each-category-selected{
        width: 200px;
        text-align: center;
    }
    .each-category{
        width: 200px;
        text-align: center;
    }
    .all-brands{
        z-index: 0;
        overflow-y: scroll;
    }
    .each-brand{
        width:72vw;
    }
}