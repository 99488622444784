.nn-main{
    position:fixed;
    height: 80px;
    top:0px;
    width: 100vw;
    padding: 0px 14vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    z-index: 9;
    border-bottom: 5px solid #277EFB;
    background: #fff;
// position: relative;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(90deg, #277EFB, #B580F2) 1 stretch;
}

.nn-btn-app{
    font-style: normal;
font-weight: 600 !important;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;
text-align: center;
width: 136px;
height: 39px;
background-color: #16171A;
// margin-left: 40px;
justify-content: center;
border-radius: 35px;
margin-bottom: 0;
color: #fff;
position: relative;
overflow: hidden;
&:hover{

  cursor: pointer;
  font-weight: 600;
}

}
.nn-btn-app::after {
  background: white;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -37px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all .55s cubic-bezier(.19,1,.22,1);
  width: 50px;
  z-index: -10;
}

.nn-btn-app:hover::after{
  left: 120%;
  transition: all .55s cubic-bezier(.19,1,.22,1);
  z-index: 50;
}
  
  .nn-btn-app:hover::after{
    left: 100%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }
.nn-img{
    height: 40px;
}

.nn-arrow{
    background: rgba(241, 244, 246, 0.5);
    border-radius: 5px;
    margin-left: 15px;
    padding: 5px 10px;
    transition: all ease .3s;
    // transform: rotate(180deg);
    &:hover{
        transform: scale(1.1);
        transition: all ease .6s;
    }
}

.nn-left{
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
}
.l1:hover{
    transform: scale(1.1);
    transition: all ease .6s;
}

.nn-right{
    display: flex;
    align-items: center;
    height: 80px;
    gap:3rem;
}

.nn-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #16171A;
    padding-left:0px;
    cursor: pointer;
    &:hover{
        transform: scale(1.1);
        transition: all ease .3s;
    }
}

.nn-btn{
    margin-left: 10px;
    padding: 13px 25px;
    text-align: center;
    background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
    border-radius: 35px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    width: 136px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover{
    
      cursor: pointer;
      font-weight: 600;
    }
}

.nn-btn::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -37px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .nn-btn:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

//   .nn-box-main{
//     height: 200px;
//     width: 300px;
//     border-radius: 20px;
//     background-color: black;
//   }


.boxinside{

// display: flex;
// align-items: center;
// justify-content: center;

background: #FFFFFF;

cursor: pointer;
img{
  height: 60px;
}
.boxinsideinner{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px !important;
  height: 55px !important;
  border: 1px solid #E5E5E5 !important;
  border-radius: 5px !important;
}
// &:hover{
//   transform: translateY(-1rem);
//   p{
//     display: flex;
//     font-style: normal;
// font-weight: 500;
// font-size: 12px;
// line-height: 15px;
// display: flex;
// align-items: center;

// text-align: center;
// width: 119.57px;
// justify-content: center;
// color: #16171A;margin-top: 0.6rem !important;
//   }
// }

&:hover{
  transform: scale(1.05);
}

  }

