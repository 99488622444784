.landingPage {
  display: flex;
  flex-direction: column;
  .pageWrap {
    // height: 0;
    // flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .patnershipMaster{
      height: 100%;
      width:72vw;
      // margin: auto;
      margin: 0 9vw;
      color: #16171A;
      overflow: hidden;
      display: flex;
      .selectoptions{
        height: 100%;
        width: 35vw;
        // margin-left: 3vw;
        border-right: 0.5px solid #EFEFEF;
      }
      .abcd{
        margin-top: -4vh;
        height: 100vh;
        width: 44vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .pMaintitle{
          font-weight: 800;
          font-size: 45px;
          line-height: 45px;
          padding: 0px 0px 45px 60px;
        }
      }
      .partnershipSection{
        height: 60vh;
        width: 100%;
        overflow-y: scroll;
        padding: 0px 0px 0 60px;
        .psubhead1{
          font-weight: 700;
          font-size: 16px;
          padding: 20px 0;
          // margin-top: 40px;
        }
        .psubhead{
          font-weight: 700;
          font-size: 16px;
          padding: 20px 0;
          margin-top: 40px;
        }
        .ptextbox{
          border: 0.5px solid #EFEFEF;
          padding: 15px;
          border-radius: 7px;
        }
        .pinput{
          border: none;
          height: 40px;
          width: 100%;
          border-radius: 7px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        .ptextarea{
          resize: none;
          border: none;
          height: 140px;
          width: 100%;
          border-radius: 7px;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
        .pinput .ptextarea::placeholder {
          opacity: 0.5;
        }
        
        .pinput .ptextarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
            opacity: 0.5;
        }
          
        .pinput .ptextarea::-ms-input-placeholder { /* Microsoft Edge */
            opacity: 0.5;
        }

        /* Chrome, Safari, Edge, Opera */
        .pinput::-webkit-outer-spin-button,
        .pinput::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        .pinput[type=number] {
          -moz-appearance: textfield;
        }

        .multiselectbox{
          display: flex;
          flex-wrap: wrap;
        }

        .eachselect{
          cursor: pointer;
          width: 21%;
          height: 80px;
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // justify-content: center;
          border: 0.5px solid #EFEFEF;
          // padding: 15px;
          border-radius: 7px;
          margin: 10px;
          .cardtitle{
            height: 80px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          &:hover{
            .cardtitle{
              transform: scale(1.05);
              transition: 0.1s ease;
              font-weight: 700;
            }
          }
        }
        .selectedbox{
          border: 2px solid #16171A;
        }
        
        .finalsubmit{
          width: 226px;
          height: 66px;
          margin: 40px 0;
          font-weight: 800;
          font-size: 18px;
          line-height: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #16171A;
          border-radius: 10px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
        }
        .finalsubmit::after {
          background: white;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
          transition: all .55s cubic-bezier(.19,1,.22,1);
          width: 50px;
          z-index: -10;
        }
        
        .finalsubmit:hover::after{
          left: 120%;
          transition: all .55s cubic-bezier(.19,1,.22,1);
          z-index: 50;
        }
        }
    }
  }
}

.pagemask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden !important;
}

.finalBox{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirmationbox{
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.endbox{
  background-color: white;
  height: 50%;
  width: 80%;
  border-radius: 35px;
}
.imgbox{
  height: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.otclogo{
  height: 50px;
  width: 250px;
}
.textbox{
  padding: 70px;
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.confirmtext{
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
}

.buttonBox{
  height: 20%;
  width: 100%;
  display: flex;
  font-weight: 800;
  font-size: 20px;
  line-height: 40px;
  bottom: 0;
}

.homebtn{
  width: 50%;
  background-color: white;
  color: #16171A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 35px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.homebtn::after {
  background: #16171A;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all .55s cubic-bezier(.19,1,.22,1);
  width: 50px;
  z-index: -10;
}

.homebtn:hover::after{
  left: 120%;
  transition: all .55s cubic-bezier(.19,1,.22,1);
  z-index: 50;
}


.inquiry{
  width: 50%;
  background-color: #16171A;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom-right-radius: 35px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.inquiry::after {
  background: white;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all .55s cubic-bezier(.19,1,.22,1);
  width: 50px;
  z-index: -10;
}

.inquiry:hover::after{
  left: 120%;
  transition: all .55s cubic-bezier(.19,1,.22,1);
  z-index: 50;
}
