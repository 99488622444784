.individualmaster{
    padding-top: 100px;
    height: calc(100vh);
    overflow-x: hidden;
}
.main-box1{
    background-image: url('../../../static/images/products/eachproduct/product_main_img.svg');
    background-size: cover;
    height: 50%;
    width: 100vw;
    // padding: 0px 35px 0px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.main-card-1{
    height: 80%;
    margin: auto;
    width:72vw;
    display: flex;
    justify-content: space-between;
}

.left-main-box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 50%;
}

.right-main-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 40%;
}

.right-main-card-1{
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 85%;
    width: 100%;
    background-color: #FFFFFF;
    margin-right: 30px;
    border-radius: 30px;
}

.head-box{
    display: flex;
}

.product-img1{
    height: 50px;
    width: 50px;
    margin-right: 10px;
}

.product-title1{
    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
    color: #FFFFFF;
}

.desc-box{
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    color: #FFFFFF;
}

.second-box1{
    height: 50%;
    width:72vw;
    margin: auto;
    background-color: white;
}

.head-text1{
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: #050505;
}

.email-box-1{
    margin: 25px 0px;
    border: 0.5px solid #E7E7E7;
    border-radius: 20px;
    height: 50px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-email{
    padding-left: 20px;
    width: 90%;
    border: none;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #050505;
}

.submit-box-1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    height: 50px;
    width: 150px;
    background: #16171A;
    border: 1px solid #16171A;
    border-radius: 35px;
    color: #FFFFFF;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .submit-box-1::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .submit-box-1:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

  .allcardbox1{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0px;
  }

  .menu-head1{
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #050505;
  }

  .menu-desc1{
    font-weight: 300;
    font-size: 12px;
    line-height: 28px;
    color: #050505;
  }
  

  .eachmenu{
    padding: 20px;
    width: 31.33%;
    margin-right: 2%;
    background: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    border-radius: 15px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;
    &:hover{
        .check1-text{
            color: #16171A;
            display: inline-flex;
            margin-left: 9px;
            margin-top: 5px;
            font-size: 15px;
            font-weight: 700;
        }
        .clickarrow-img{
            margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 20px;
        }
        .zoom1{
            background: #fffbf0;
            height: 45px;
            text-align: center;
            width: 120px;
        }
        .icon1-img{
            margin-top: 0.3rem;
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
            transition: all .3s ease-in-out;        
        }
        box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
    }
}

.below-btn{
    display: none;
}

@media only screen and (min-width: 0px) and (max-width: 900px){
    .individualmaster{
        height: 88vh;
        overflow-y: scroll;
    }
    .main-box1{
        height: 60vh;
    }

    .main-card-1{
        height: 90%;
        margin-left: 50px;
        display: flex;
        flex-direction: column;
    }

    .left-main-box{
        width: 90vw;
        padding-right: 30px;
    }

    .below-btn{
        margin-right: 80px;
        cursor: pointer;
        height: 50px;
        width: 250px;
        border: 1px solid #FFFFFF;
        border-radius: 40px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 800;
        font-size: 20px;
        overflow: hidden;
        position: relative;
      }
    
      .below-btn::after {
        background: white;
        content: "";
        height: 155px;
        left: -75px;
        opacity: 1;
        position: absolute;
        top: -50px;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
        transition: all .55s cubic-bezier(.19,1,.22,1);
        width: 50px;
        z-index: -10;
      }
      
      .below-btn:hover::after{
        left: 120%;
        transition: all .55s cubic-bezier(.19,1,.22,1);
        z-index: 50;
      }

    .right-main-box{
        display: none;
    }
    .allcardbox1{
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: 30px;
        // padding-left: 50px;
    }
    .eachmenu{
        margin: 20px 0px;
        width: 100%;
    }
    .head-box{
        padding-bottom: 10px;
    }

    .product-title1{
        font-size: 27px;
        line-height: 35px;
    }

    .product-img1{
        height: 35px;
        width: 35px;
    }

    .desc-box{
        font-size: 15px;
        line-height: 25px;
        padding-bottom: 10px;
    }
}