@keyframes slide-left {
    100% { transform: translateX(0%); }
  }

.fp-main{
    height: 100%;
    width: 100%;
    animation: slide-left .4s forwards;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.fp-nav{
    display: flex;
    align-items: center;
    // position: sticky;
    background-color: white;
    border-radius: 25px 25px 0 0;
    top: 0;
    border-bottom: 0.5px solid #E7E7E7;
    padding: 20px;
    // z-index: 2;
}

.each-fp-nav{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px;
    border: 0.5px solid #E7E7E7;
    background-color: white;
    border-radius: 25px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #16171A;
    cursor: pointer;
    &:hover{
        transform: scale(1.1);
        transition: all ease .3s;
        font-weight: 800;
    }
}

.each-fp-nav-active{
    transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
    background-color: #E7E7E7;
    font-size: 13px;
    font-weight: 800;
}

.fp-productbox{
    height: calc(100% - 78.4px);
}

@media only screen and (min-width: 0px) and (max-width: 900px){

    .fp-nav{
        display: none;
    }
}