.nav-menu-container {
  width: 100vw;
}
.cardsMenu{
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
  /* padding-right: 14vw; */

}
.big-box0 {
  display: none;

  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  // padding-top: 38px;
  position: fixed;
  top: 80px;
  left: 0;
  height: 8rem;
  z-index: 17;
  width: 100%;
  padding: 0 14vw;
  p{
    text-align: center;
  }
  .logoheader{
    padding-right: 3vw;
    border-right: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    padding-right: 5vw;
    // margin-right: 4vw;
    &:hover{
      img{
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
  .boxinside{

    display: flex;
    align-items: center;
    // justify-content: center;
    
    background: #FFFFFF;

img{
  height: 32px;
}
.boxinsideinner{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px !important;
  height: 55px !important;
  border: 1px solid #E5E5E5 !important;
  border-radius: 5px !important;
}
&:hover{
  // transform: translateY(-1rem);
  p{

  }
}

  }
  p{
    color: #16171A;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin-left: 12px !important;
  }
}

.big-box1 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 80px;
  right: calc(11vw + 400px);
  z-index: 17;
}

.big-box2 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 80px;
    /* right: calc(27vw + 420px); */
    z-index: 17;
    left: 50%;
    transform: translate(-46%, 0%);
  z-index: 17;
}

.big-box3 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  right: calc(6vw);
  top: 80px;
  z-index: 17;
  display: flex;
  justify-content: space-between;
}

.menus-left{
    width: fit-content;
}

.menus-right{
    width: fit-content;
    margin-left: 40px;
    border-left: 1px solid #E7E7E7;
    padding-left: 40px;
}

.big-box4 {
  padding: 35px;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  position: fixed;
  top: 80px;
  right: calc(35vw);
  z-index: 17;
}

.menu-title{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #16171A;
}

.menu-desc{
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #16171A;
}

.each-menu{
    cursor: pointer;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    &:hover{
        background-color: #e7e7e7;
        border-radius: 15px;
    }
}

.menus{
    width: fit-content;
    cursor: pointer;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    &:hover{
        background-color: #e7e7e7;
        border-radius: 15px;
    }
}

.menu-img-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}



@media screen and (min-width:0px) and (max-width: 900px){
.cardsMenu {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
  padding-right: 0vw;
  display: grid;
  grid-template-columns: 150px 150px;
  padding-right: 20px;
  row-gap: 3.5rem;
}
}