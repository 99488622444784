$w: 4rem;
$h: .5*$w;
$a: 90deg 22.5deg 22.5deg;

// body {
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin: 0;
// 	height: 100vh;
// 	perspective-origin: calc(50% + #{$w}) calc(50% - #{$w});
// 	perspective: 32em;
// 	background: radial-gradient(#e5e5e5, #ddd);
// }

[type='checkbox'] {
	position: absolute;
	left: -100vw;
	
	+ label {
		--s: 0;
    position: relative;
    border: solid 2px #E5E5E5;
    width: 3rem;
    height: 1.5rem;
    border-radius: 5rem;
    transform-style: preserve-3d;
    // box-shadow: 0.5rem 0.875rem 0 -0.25rem #e0e0e0, 0.625rem 0.625rem 0 -0.25rem #e0e0e0, 0.5rem 0.875rem 0.625rem -0.125rem rgb(191 191 191 / 75%), inset 0.125rem -0.125rem 0.5rem rgb(245 245 245 / 50%), inset 0.75rem 0.75rem #dfdfdf, inset 0.75rem 0.75rem 0.75rem rgb(191 191 191 / 85%), inset 0 1rem 0.75rem rgb(191 191 191 / 65%);
    color: white;
    font-size: 0;
    -webkit-tap-highlight-color: white;
	background: white;
    cursor: pointer;
    cursor: pointer;
		
		&:before, &:after {
			position: absolute;
			width: 1.2rem;
			height: 19px;
			border-radius: 45rem;
			transition: 0.35s cubic-bezier(0.21, 0.61, 0.35, 1);
			content: "";
		}
		
		&:before {
			// top: calc(30% + .075rem);
			// transform-origin: 100% 0;

			// background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
		}
		
		&:after {
			top: 1px;
			    left: 3px;
    /* left: 0.75rem; */
 
    background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
		}
	}
	
	&:checked + label { --s: 1 }
}