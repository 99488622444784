.tabs-tacker {
  background: linear-gradient(93.86deg, #277EFB 9.07%, #B580F2 141.08%);;
  height: 253px;
  position: relative;
  padding-left: 4%;
  margin-top: 1.5rem;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 1.5rem;
  .tab-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-right: 27px;
    color: #ffffff;
    padding-bottom: 12px;
    opacity: 0.5;
    cursor: pointer;
    &:hover{
        transform: scale(1.1);
    }
  }
  h1 {
    margin-bottom: 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    color: #ffffff;
  }
  .tab-tacker-box {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
.box-tacker {
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
  top: -72px;
  display: flex;
  justify-content: space-between;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #16171A;
    margin-bottom: 0;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 43px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #16171A;
  }
  .price-value-tacker {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
margin-left: 10px;
    color: #4dc089;
    .round-img {
      width: 13px;
      height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background: #4dc08868;
      //opacity: 0.25;
      margin-right: 10px;
    }
  }
}
.citixen-box {
  height: 183px;
  background-color: white;
  border-radius: 15px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 3%;
  &:hover{
    p{
        transform: scaley(1.1);
    }
    .price-value-tacker{
        transform: scaley(1.1);
    }
    h2{
        transform: scaley(1.1);
    }
  }
}
.rightside-tacker {
  height: 183px;
  background-color: transparent;
  border-radius: 15px;
  width: 68%;

  position: relative;
  .yearly-tavker {
    background-color: #ffff;
    // background-image: -webkit-linear-gradient(
    //   150deg,
    //   #277efb calc(100% - 152px),
    //   #ffff 2px
    // );

    background-image: -webkit-linear-gradient(
      205deg,
      #277efb calc(100% - 202px),
      white -72px
    );
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    // padding-left: 3%;
    // padding-right: 4%;
    // background-image: linear-gradient(
    //   150deg,
    //   #277efb calc(100% - 161px),
    //   #ffff 2px
    // );

    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 29px;
    width: 100%;
    height: 3rem;
    position: absolute;
    // padding-top: 8px;
    background: transparent;
    top: -4px;
    // &:after {
    //   content: "";
    //   width: 200%;
    //   height: 0;
    //   padding-top: 200%;
    //   border-radius: 100%;
    //   background: #f7f7f7;
    //   position: absolute;
    //   top: 60%;
    //   left: 50%;
    //   transform: translateX(-50%);
    // }
    .year-tab {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #fff;
      cursor: pointer;
      width: 16.6666%;
      height: 3rem;
      // opacity: 0.25;
      padding-bottom: 21px;
      padding-right: 2%;
      &:hover{
        // transform: scale(1.1);
      }
    }
  }
}
.tacker-main {
  background: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
}
.citixen-box-right {
  height: 90%;
  border-radius: 15px;
  cursor: pointer;
  background-color: white;
  padding-left: 3%;
  width: 31.3333%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  //   padding-left: 3%;

  &:hover{
    // box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    border:0.5px solid #E5E5E5;
    // p{
    //   transform:scale(1.1) !important;
    // }
    // h2{
    //   transform:scale(1.1) !important;
    // }
    // .price-value-tacker{
    //   transform:scale(1.1) !important;
    // }
  }
  &:nth-child(1) {
    border-bottom-left-radius: 15px;
  }
  &:nth-child(3) {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.combine-box {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 29px);
  align-items: center;
  background: white;
  margin-top: 29px;
  border-top-right-radius: 15px;
  padding: 1rem;
  &:hover{
    // p{
    //     transform: scaley(1.1);
    // }
    // .price-value-tacker{
    //     transform: scaley(1.1);
    // }
    // h2{
    //     transform: scaley(1.1);
    // }
}
}

.bottom-table-tracker {
  height: 100%;
  border-radius: 15px;
  margin-left: 4%;
  margin-right: 4%;
  height: calc(100% - 11.5rem);
  background-color: #fff;
  position: relative;
  top: -56px;
  border-radius: 25px 25px 0px 0px;

}

.right-side-dah-tacker {
  height: 100vh;

  width: calc(78% - 24px);
  overflow: hidden;
}

.contain-bottom {
  border-bottom: 0.5px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20%;
  height: 5rem;
    padding: 0 2rem;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
margin-bottom: 1rem;
  &:nth-child(5) {
    border-bottom: 0px;
  }
  &:hover{
transform: translateY(-2px)
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #16171A;
    margin-bottom: 0;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #16171A;
    margin-bottom: 0;
  }
}
.gobleName{
  font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
margin-left: 4%;
    margin-right: 4%;
    position: relative;
    top: -5rem;
color: #16171A;

padding-top: 2rem;
}
.tableTracker{
  padding-top: 1rem;
  overflow: scroll;
  margin-left: 3%;
  margin-right: 3%;
    height: calc(100% - 56px);
}
.tacker-menu {
  height: 100vh;
  width: 22%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 43px;
  background-color: #fff;
  img {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  label {
    width: 100%;
    height: 58px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    color: #ffffff;
    justify-content: center;
    background: linear-gradient(93.86deg, #277EFB 9.07%, #B580F2 141.08%);
    border-radius: 50px;
    &:hover {
        transform: scale(1.1);
      }
  }

  .menu-tacker-list {
    height: 39px;
    width: 100%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-left: 8%;
    color: #16171A;
    margin-bottom: 1.2rem;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 35px;
    &:hover{
        transform: scale(1.1);
    }
  }
}

.continer-height {
  height: calc(100% - 297px);
  background: #f5f5f5;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}


.headerSectionTable{
  display: flex;
  align-items: center;

height: 34px;
border-radius: 25px 25px 0px 0px;
justify-content: space-between;
background: #FFFFFF;
border-bottom: 0.5px solid #E5E5E5;
padding-left: calc(3% + 2rem);
   padding-right: calc(3% + 2rem);

  //  margin-left: 3%;
  //  margin-right: 3%;
  //  padding: 0 2rem;
   div{
    font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;

color: #16171A;

   }
}


.contain-bottom-des {
  border-bottom: 0.5px solid #e7e7e7;
  display: flex;
  align-items: start;
  justify-content: space-between;
  /* height: 20%; */
  /* height: 5rem; */
  padding: 0 2rem;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  &:nth-child(5) {
    border-bottom: 0px;
  }
  &:hover{
h1{
    transform: scale(1.1);
}
h2{
    transform: scale(1.1);
}
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
  

    color: #16171A;
    margin-bottom: 0;
  }
  h5{
    font-style: normal;
font-weight: 300;
font-size: 11px;
line-height: 19px;
/* or 173% */
margin-bottom: 0;
display: flex;
align-items: center;

color: #16171A;

  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #16171A;
    margin-bottom: 0;
  }
}


.slectedlabel{
  font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;

color: #16171A;
margin-bottom: 1rem;
}


.hoidlingtable{
  width: 106px;
height: 34px;
left: 559px;
top: 443px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;
text-align: center;

color: #16171A;
justify-content: center;
background: white;
border-radius: 35px;
margin-right: 10px;
&:hover{
  transform: scale(1.1);
}
}
.sectionHoilding{
  display: flex;
  align-items: center;
  position: relative;
  top: -5rem;
  padding-top: 2rem;
  .gobleNamecustom {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-left: 4%;
    margin-right: 4%;

    color: #16171A;
  
}
}