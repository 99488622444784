
.scrollspy-example {
    position: relative;
    height: 65vh;
    overflow: auto;
  }

  .menu-each-sc{
    height: 8vh;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.each-img-sc{
    height: 20px;
    width: 20px;
    margin-right: 12px;
}

.each-title-sc{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #212121;
}

.left-menu{
    padding-top: 20px;
    padding-left: 60px !important;
    height: 65vh;
    overflow-y: scroll;
    border-right: 1px solid #F5F5F5;
}

.right-menu{
    height: 64vh;
    padding: 20px;
    border-bottom: 1px solid #F5F5F5;
}

.each-right-box-sc{
    font-weight: 800;
    font-size: 44px;
    line-height: 54px;
    color: #16171A;
    padding-bottom: 20px;
}

.nav-pills.menu-sidebar .nav-link.active, .nav-pills.menu-sidebar .show>.nav-link {
    background-color: #F5F5F5 !important;
    max-width: 300px;
    box-shadow: none; 
    color: #262626 !important;
    font-weight: 800 !important; 
    border-left: none !important; 
    border-radius: 10px !important;
    .each-img-sc{
        margin-left: 10px;
    }
    .each-title-sc {
        font-weight: 800 !important;  
    }
}

.pc-main-box1{
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 99999;
}

.seachbox-sc{
    margin-top: 20px;
    height: 8vh;
    border: 1px solid #F5F5F5F5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    max-width: 300px;
    padding: 0px 20px;
    margin-bottom: 30px;
}

.main-sc{
    padding-top: 20px;
    display: flex;
}

.main-img-sc{
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.main-title-sc{
    font-weight: 800;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #16171A;
}