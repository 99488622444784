.starfish{
    background: linear-gradient(90deg, #F05C4D 0%, #B580F2 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 14vw;
    overflow: hidden;
    position: relative;
    padding-top: 75px;
    h1{
        color: #FFF;
font-size: 55px;
font-family: Montserrat;
font-weight: 600;
line-height: 75px;
width: 569px;
    }
    .blackimg{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1040px 0 0 1040px;
        height: 100vh;
        position: absolute;
        right: -420px;
        padding-right: 400px;
        width: 1040px;
        background: #16171A;
    }
    label{
        margin-top: 3rem;
        color: #FFF;
text-align: center;
font-size: 22px;
font-family: Montserrat;
font-weight: 600;
display: flex;
width: 323px;
height: 58px;
align-items: center;
margin-bottom: 0;
justify-content: center;
border-radius: 30px;
margin-top: 1rem;
// #277EFB, #B580F2
background: linear-gradient(275.12deg,#277efb 7.36%,#b580f2 140.3%);
&:hover{
    transform: scale(1.1);
}
    }
}
.mainStarfish{
    overflow: scroll;
    height: 100vh;
}
.section2stair{
    background: linear-gradient(90deg, #F05C4D 0%, #B580F2 100%);
    padding: 0 14vw;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    h1{
        color: #FFF;
font-size: 45px;
font-family: Montserrat;
font-weight: 600;
line-height: 65px;
    }
    .cards{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 3rem;
        .items{
            height: 310px;
            border-radius: 15px;
background: rgba(255, 255, 255, 0.20);
width: 32%;
margin-bottom: 2.33%;
padding: 1.5rem 2.5rem;
h4{
    color: #FFF;
font-size: 26px;
font-family: Montserrat;
font-weight: 700;
text-align: right;
margin-bottom: 1rem;
}
h2{
    color: #FFF;
font-size: 23px;
font-family: Montserrat;
font-weight: 500;
margin-bottom: 1.5rem;
}
span{
    color: #FFF;
    font-size: 17px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 26px;
    text-decoration: underline;
    transition: all ease .2s;
    cursor: pointer;
    &:hover{
        transition: all ease .2s;
       font-size: 18px;
    }
}
h5{
    color: #FFF;
font-size: 17px;
font-family: Montserrat;
font-weight: 500;
line-height: 26px;
}
        }
    }
}

@media only screen and (min-width: 100px) and (max-width: 900px){
.starfish{
height: auto;
padding-right: 6vw;
padding-left: 6vw;
 .blackimg {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0rem 0rem 50vw 50vw;
    height: 14rem;
    position: absolute;
    left: 0px;
    padding-right: 0px;
    top: 71px;
    width: 100%;
    padding-bottom: 2rem;
    background: #16171A;
    img{
        width: 60%;
    }
}
label {
    margin-top: 3rem;
    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-family: Montserrat;
    font-weight: 600;
    display: flex;
    width: 80%;
    height: 52px;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
    border-radius: 30px;
    margin-top: 1rem;
    background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
    margin: auto;
    margin-top: 3rem;
}
h1{
    color: #FFF;
    font-size: 32px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 45px;
    padding-top: 16rem;
    width: auto;
    text-align: center;

}


}
.section2stair{
    padding-right: 6vw;
padding-left: 6vw;
h1{
    color: #FFF;
    font-size: 26px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 45px;
    width: auto;
    text-align: center;
}
.cards{
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    .items{
        height: 251px;
        border-radius: 15px;
background: rgba(255, 255, 255, 0.20);
width: 100%;
margin-bottom: 2rem;
h5 {
    color: #FFF;
    font-size: 13px;

    }
    h2 {
        color: #FFF;
        font-size: 19px;
    }
    h4{
        font-size: 19px;
    }
    span {
        color: #FFF;
        font-size: 13px;
        
        
}}}
}
}