@import "../../../static/scss/mixin.scss";


.productmain-master {
    padding-top: 79px;
    background-color: #F5F5F5;
    height: calc(100vh);
    width: 100vw;
    color: #16171A;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 6rem;


    .section1 {
        height: 78vh;
        width: 100vw;
        background-color: #F5F5F5;

    }

    .full-box {
        height: 60vh;
        width: 72vw;
        margin: auto;
        color: #16171A;
        display: flex;
        justify-content: space-between;
        padding-bottom: 127px;
    }

    .section1-leftbox {
        width: 41vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        padding-bottom: 5vh;
        justify-content: center;

        .mini-head {
            font-weight: 400;
            font-size: 32px;
            line-height: 39px;
            padding-bottom: 30px;
            color: #16171A;
        }

        .main-head {
            font-weight: 800;
            font-size: 48px;
            line-height: 65px;
            color: #16171A;
        }

        .start-btn {
            margin-top: 50px;
            height: 60px;
            width: 200px;
            color: #16171A;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #16171A;
            border-radius: 25px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            background-color: #F5F5F5;
            // overflow: hidden;
            // position: relative;
            @include btn-animate(#16171A);
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
                transition: ease .2s;
            }
        }
    }

    .section1-rightbox {
        margin-top: 6vh;
        width: 41vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;


        .section1-main-img1 {
            width: 31vw;

        }
    }

    .section2 {

        // height: calc(100vh - 120px);

        width: 100vw;
        // background-color: rgba(237, 237, 237, 0.37);
        background-color: #F5F5F5;
        // position: sticky;
        // top: 0;

        .section2-part1 {
            height: 275px;
            width: 100vw;

        }

        .section2-part1-update {
            // height: 100vh;
            width: 100vw;
            background-color: #FFFFFF;
        }

        .section2-main-card {
            width: 72vw;
            height: auto;
            margin: auto;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            top: -123px;
            //  padding-top: 2rem;

        }

        .section2-main-head {
            font-weight: 500;
            font-size: 37px;
            line-height: 43px;
            color: #16171A;
            text-align: center;
            padding-bottom: 50px;
        }

        .section2-all-pairs {
            width: 100%;
            /* height: 190px; */
            /* overflow-x: scroll; */
            display: flex;
            /* align-items: center; */
            /* justify-content: center; */
            flex-wrap: wrap;
        }

        // @keyframes left_to_right{
        //     from{
        //         margin-left: 91vw;
        //     }
        //     to{
        //         margin-left:9vw;
        //     }
        // }

        @keyframes fadeIn {

            // 0% { opacity: 0; }
            // 50% { opacity: 0.5; }
            // 100% { opacity: 1; }
            0% {
                opacity: 0;
            }

            25% {
                opacity: .25;
            }

            50% {
                opacity: .50;
            }

            75% {
                opacity: .75;
            }

            100% {
                opacity: 1;
            }
        }



        .update-all-pairs {
            width: 100vw;
            padding-right: 10vw;
            height: 150px;
            padding-left: 30px;
            // margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            animation: fadeIn .7s linear;
            // position: sticky;
            // top: 0;
            background-color: #FFFFFF;
            border-bottom: 1px solid #F5F5F5;
            border-left: 1px solid #F5F5F5;
            border-right: 1px solid #F5F5F5;
        }

        .scroll-main-body {
            width: 100vw;
            height: calc(100vh - 270px);
            margin: auto;
            overflow: hidden;
            border-left: 1px solid #F5F5F5;
            border-right: 1px solid #F5F5F5;
            // padding-top: 60px;
        }

        .section2-each-pair {
            width: 20%;
            height: 230px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            /* border-left: 1px solid #EFEFEF; */
            /* border-bottom: 1px solid #EFEFEF; */
            border: 1px solid #EFEFEF;
            border-left: 0px solid #EFEFEF;

            &:hover {
                transform: scale(1.02);
                transition: ease .2s;
            }
        }

        .section2-each-img {
            height: 90px;
            width: 90px;
            margin-bottom: 10px;
        }

        .section2-each-title {
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            text-align: center;
            color: #16171A;
        }

        .update-each-title {
            display: flex;
            font-weight: 600;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            color: #16171A;
        }

        .update-each-pair {
            // padding-top: 30px;
            width: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                padding-bottom: 10px;
                transition: ease .2s;

                .update-each-title {
                    display: flex !important;
                }
            }
        }

        .update-each-img {
            height: 40px;
            width: 40px;
            margin-bottom: 10px;
        }


    }

}