@import "../../../static/scss/mixin.scss";

iframe{
  border-radius: 10px;
}

.scrollmainfull{
  scroll-behavior: smooth;
}

.statSectionmob{
  display: none;
}
.requestPricing{
  background: #16171A;
  border: 0.5px solid #16171A;
  border-radius: 25px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 35px;
  &:hover{
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
  }
}
.closebox{
  width:72vw;
  margin: auto;
  display: flex;
  justify-content: end;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.webintegratedLeftCards{
  display: flex;
  justify-content: space-between;
  width: 100%;
  .eachdescbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    width: 15%;
    .eachLeftCards2{
      width: 100%;
      height: 150px;
      padding: 0;
      justify-content: center;
      border: 1px solid #16171A;
    }
    .eachLeftCards1{
      padding: 0;
      width: 160px;
      height: 160px;
      justify-content: center;
      border: 1px solid #E5E5E5;
    }
    .modelimg1{
      width: 70px;
      height: 70px;
      margin: 0px;
    }
  }
}

//// grid
.gdbox{
  margin-top: 85px;
  display: grid;
  grid-template-columns: 1.1fr 9.9fr 1.1fr;
}

.gdbox1{
  padding: 0px 14vw;
  width: 100%;
}

/// end

.fixed{
  color: #16171A;
}

@keyframes slide-in {
  100% { transform: translateY(0%); }
}

@keyframes slide-left {
  100% { transform: translateX(0%); }
}

.scrollNav{
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 1;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-bottom: .5px solid #e5e5e5;
  animation: slide-in .4s forwards;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.square{
  width: 95%;
  height: 63vh;
  color: #16171A;
}

.heroText{
 
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 75px;
  display: flex;
  align-items: center;
  color: #16171A;
  z-index: 1;
  margin-bottom: 15px;
  }

  .herosubText{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 55px;
    display: flex;
    align-items: center;
    color: #16171A;
    z-index: 1;
    margin-bottom: 20px;
    }

  .containerLeftRight{
    display: flex;
    justify-content: space-between;
    height: 63vh;
  }

  .provideCards{
    width: 100%;
//     margin: auto;
//     width:72vw;
//     display: flex;
//     justify-content: space-between;
//     grid-column: 1;
//     display: grid;
//     grid-auto-flow: column;
//     gap: 2rem;
//     overflow-x: visible;
//     padding-left: 2rem;
//     padding-right: 2rem;
//  padding-top: 2rem;
//     padding-bottom: 2rem;
//     overflow-y: scroll;
  }

  .provideimg{
    height: 120px;
    width: 120px;
  }


  .eachProvideCard{
    padding: 3%;
    // width: 32vw !important;
    // margin: 0px 3% 30px 0px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding-top: 2.5rem;
    padding-bottom: 3rem;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
    cursor: pointer;
    height: 30rem;
    border-radius: 25px;
    &:hover{
      // transform: scale(1.1);
    }
    .zoom1 {
      background: #f3f4f5;
      border: 0;
      border-radius: 30px;
   
      cursor: pointer;
      display: inline-block;
      height: 40px;
      overflow: hidden;
      transition: all 0.3s ease-out;
      width: 40px;
    }
    .clickarrow-img {
      height: 20px;
      width: 20px;
    }
    .check1text{
      display: none;
    }

    &:hover{
      // .provideImgBox{
      //   transform: scale(1.1);
      //   transition: ease-in .3s;
      // }
      .check1text{
        color: #16171A;
        display: inline-flex;
        margin-left: 9px;
        margin-top: 5px;
        font-size: 15px;
        font-weight: 700;
        }
        .clickarrowimg{
            margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 20px;
        }
    
        .zoom1{
          background: linear-gradient(180deg, #277EFB 0%, #B580F2 100%);
            height: 45px;
            text-align: center;
            width: 120px;
            outline: none;
        }
        .icon1img{
            margin-top: 0.3rem;
            -webkit-transform: scale(1.5);
            transform: scale(1.5);
            transition: all .3s ease-in-out;        
        }
//         border: 0.5px solid #16171A;
// border-radius: 25px;
    }
  }

  .provideHead{
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
  
    // padding-bottom: 20px;
//     background: linear-gradient(90deg, #277EFB 0%, #B580F2 121.81%);
// -webkit-background-clip: text;
// -webkit-text-fill-color: transparent;
// background-clip: text;
// text-fill-color: transparent;
  }

  .providesubHead{
    font-weight: 500;
    font-size: 16px;
    color: #16171A;
    padding-bottom: 20px;
    font-weight: 400;
font-size: 16px;
line-height: 45px;
/* or 225% */

color: #16171A;
  }

  .provideDesc{
    font-weight: 300;
    font-size: 14px;
    color: #16171A;
    line-height: 32px;
    padding-bottom: 45px;
  }
  
  .provideButtonBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .requestBox{
    height: 11rem;
    width: 100vw;
    transform: scale(1.01);
    background-image: url('../../../static/bck.png');
    background-size: cover;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 0px;
  }

  .reqinnerbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .headtext{
    color: #fff;
    font-size: 41px;
    font-weight: 600;
    line-height: 67px;
    width: 45vw;
    z-index: 1;
  }

  .box1{
    height: 7vh;
    width: 35vw;
  }
  
  .box4{
    margin-right: 0px;
    cursor: pointer;
    height: 7vh;
    width: 14vw;
  
    border-radius: 40px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px; 
      padding: 1.5px; 
      background:linear-gradient(45deg,#277EFB ,#B580F2); 
      -webkit-mask: 
         linear-gradient(#fff 0 0) content-box, 
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }
  }

  .box4::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .box4:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

  .box5{
    display: none;
  }
  
  .inputTexts{
    width: 95%;
    border: none;
    height: 7vh;
    border-radius: 70px;
    background-color: #FFFFFF;
    padding-left: 30px;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #16171A;
  }
  
  .subHeroText{
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 35px;
  /* or 250% */
  padding-right: 12vw;
  display: flex;
  align-items: center;
  
  color: #16171A;
  }

  .requestDemoButton{
    background: #277EFB;
  opacity: 0.9;
  border: 0.5px solid #277EFB;
  border-radius: 35px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #FFFFFF;
  @include btn-animate(#FFFFFF);
  
  
  }

  
  .watchVideoButton{
    // background: #FFFFFF;
  opacity: 0.9;
  border: 0.5px solid #16171A;
  border-radius: 35px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
  background-color: #16171A;
  color: #FFFFFF;
  @include btn-animate(#FFFFFF);
  
  }


.videoModalVisible{
  background-color: rgba(0,0,0,.75);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden!important;
  position: fixed!important;
  right: 0;
  top: 0;
  visibility: visible;
  // position: absolute; 
  width: 100%;
  height: 315;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

}
.videoModalHidden{
  visibility: hidden;
  
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0.5px solid #E5E5E5;
  padding:10px;
  border-radius: 5px;
}
  

.ctaSection{

    background: #16171A;
    color: white;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 45px;
  /* or 250% */
  
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #FFFFFF;
  // @include btn-animate(#FFFFFF);
  &:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.3s all ease-in-out;
  }
  }

  .ctaSection1{
    background: #16171A;
    color: white;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  // line-height: 45px;
  /* or 250% */
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #FFFFFF;
  // @include btn-animate(#FFFFFF);
  &:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.3s all ease-in-out;
  }
  }

  .statbox{
    width: 100%;
    background-color: rgba(237, 237, 237, 0.37);
    padding: 150px 0;
    // display: none;
  }

  .templatebox{
    width: 100%;
    background-color: #16171A;
    padding: 54px 0;
  }

  .productbox{
    width: 100%;
    background-color: #F5F5F5;

    // position: relative;
    // top: -123px;

  }

  .allmenucards{
    margin: auto;
    width:72vw;
    display: flex;
    flex-wrap: wrap;
  }
  .menucard{
    width: 30.33%;
    margin: 0px 3% 30px 0px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    // background-color: #FFFFFF;
    perspective: 1500px;
  }

  .content {
    border-radius: 20px;
    // position: absolute;
    width: 100%;
    height: 240px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
  
    transition: transform 1s;
    transform-style: preserve-3d;
  }
  
  .menucard:hover .content {
    transform: rotateY( 180deg ) ;
    transition: transform 0.5s;
  }
  
  .front{
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    backface-visibility: hidden;
  }
  
  .back {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
    position: absolute;
    height: 100%;
    width: 100%;
    background: white;
    backface-visibility: hidden;
    transform: rotateY( 180deg );
    .menuImg1{
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  
    .menuTitle1{
      color: #16171A;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
    }
  
    .menudesc1{
      color: #16171A;
      font-weight: 300;
      font-size: 12px;
      line-height: 28px;
      text-align: left;
    }

    .menunewbtn1{
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      height: 30px;
      width: 100px;
      background: #16171A;
      border: 1px solid #16171A;
      border-radius: 7px;
      color: #FFFFFF;
      overflow: hidden;
      position: relative;
      cursor: pointer;
    }
    
    .menunewbtn1::after {
      background: white;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      -webkit-transform: rotate(35deg);
      transform: rotate(35deg);
      transition: all .55s cubic-bezier(.19,1,.22,1);
      width: 50px;
      z-index: -10;
    }
    
    .menunewbtn1:hover::after{
      left: 120%;
      transition: all .55s cubic-bezier(.19,1,.22,1);
      z-index: 50;
    }
  }

  

  // .menucard::after {
  //   background: #16171A;
  //   content: "";
  //   height: 1220px;
  //   left: -655px;
  //   opacity: 1;
  //   position: absolute;
  //   top: -150px;
  //   -webkit-transform: rotate(35deg);
  //   transform: rotate(35deg);
  //   transition: all .55s cubic-bezier(.19,1,.22,1);
  //   width: 60px;
  //   z-index: -10;
  // }
  
  // .menucard:hover::after{
  //   left: 120%;
  //   transition: all .55s cubic-bezier(.19,1,.22,1);
  //   z-index: 50;
  // }

  .menuImg{
    height: 100px;
    width: 100px;
  }

  .menuTitle{
    margin-top: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #16171A;
  }

  .empowerbox{
    width: 100%;
    background-color: rgba(237, 237, 237, 0.37);
  }

  .empowercard{
    transform: scale(1.01);
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url('../../../static/bitcoin.png');
    background-size: cover;
    height: 11rem;
    // padding: 0px 80px;
  }

  .empbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:72vw;
    margin: auto;
  }

  .empowerbtn{
    // margin-right: 80px;
    cursor: pointer;
    height: 7vh;
    width: 14vw;
    // border: 1px solid #FFFFFF;
    border-radius: 40px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 1.5px;
      background: linear-gradient(45deg, #277EFB, #B580F2);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
  }
  }

  .empowerbtn::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .empowerbtn:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

  .modelbox{
    color: #16171A;
    width: 100%;
    background-color: rgba(237, 237, 237, 0.37);
    padding: 66px 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .integratedbox{
    display: flex;
    width:72vw;
    margin: auto;
    height: 700px;
    position: sticky;
    left: 0;
    margin-top: -20px;
    z-index: 0;
  }

  .integratedLeftCards{
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 20px;
    height: 700px;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }

  .mobintegratedLeftCards{
    display: none;
  }

  .eachLeftCards{
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
    color: #16171A;
    cursor: pointer;
    &:hover{
      font-size: 25px;
      transition: ease .3s;
    }
  }

  .eachRightCards{
    border-radius: 10px;
    background-color: #FFFFFF;
    width: calc(70% - 20px);
    height: 680px;
    margin-bottom: 20px;
    padding: 40px 60px 0px 60px;
    overflow-y: scroll;
  }

  .firstdescribebox{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .firstleftmainbox{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .rightTitle{
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    text-align: left;
    padding-bottom: 40px;
  }

  .describescrollbox{
    height: 470px;
    overflow-y: scroll;
  }

  .rightDesc{
    width: 100%;
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

 


  .describetablebox1{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .describetablebox{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .describetablebox2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .describelefthead{
    background-color: #E5E5E5;
    padding: 10px;
    width: 44.5%;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    color: #16171A;
  }

  .describerighthead{
    background-color: #E5E5E5;
    padding: 10px;
    width: 44.5%;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
    color: #16171A;
  }

  .describemiddlehead{
    padding: 20px 0px;
    width: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  

  .describeleft{
    padding: 20px;
    height: 100px;
    width: 44.5%;
    border-bottom: 0.5px solid #E5E5E5;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #16171A;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .describemiddle{
    height: 100px;
    padding: 20px 0px;
    width: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tablearrow{
    height: 15px;
    width: 15px;
  }

  .describeright{
    height: 100px;
    padding: 20px;
    width: 44.5%;
    border-bottom: 0.5px solid #E5E5E5;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #16171A;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .videobox{
    // margin-top: 20px;
    width: 30%;
    border-radius: 20px;
    height: auto;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    &:hover{
      transform: scale(1.02);
      transition: ease 0.3s;
      .videoheadbox{
        background-image: url('../../../static/images/about/blurback.svg');
        border-radius: 20px;
        background-size: cover;
        display: flex;
        height: 100%;
      }
    }
  }

  .videoheadbox{
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .videotitle{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-left: 10px;
  }

  .youimg{
    width: 65px;
    height: 45px;
    margin-bottom: 20px;
  }

  .rightbutton{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    height: 50px;
    width: 200px;
    background: #16171A;
    border: 1px solid #16171A;
    border-radius: 35px;
    color: #FFFFFF;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .rightbutton::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .rightbutton:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }



  .tempHead{
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-left: 80px;
    width:72vw;
    margin: auto;
    font-size: 41px;
    font-weight: 500;
    line-height: 75px;
    color: #FFFFFF;
  }

  .empowerhead{
    font-weight: 600;
    font-size: 41px;
    line-height: 65px;
    color: #FFFFFF;
    width: 65%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .empowerheadmob{
    display: none;
  }

  .empowertext{
    font-weight: 600;
    font-size: 22px;
    line-height: 45px;
    color: #FFFFFF;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .alltempcards1{
   display: none;
  }

  .eachcarouselcardfirst{
    margin: 0px 10px 30px 10px;
    width:100%;
    height: 260px;
    background-size: cover;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    color: #FFFFFF;
    border-radius: 30px;
    cursor: pointer;
    // overflow: hidden;
    // position: relative;
    display: flex;
    &:hover{
      font-size: 35px;
    }
  }

  .eachcarouselcardlast{
    margin: 30px 10px 100px 10px;
    width:100%;
    height: 260px;
    background-size: cover;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    color: #FFFFFF;
    border-radius: 30px;
    cursor: pointer;
    // overflow: hidden;
    // position: relative;
    display: flex;
    &:hover{
      font-size: 35px;
    }
  }
  

  .eachcarouselcard{
    margin: 30px 10px;
    width:100%;
    height: 260px;
    background-size: cover;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    color: #FFFFFF;
    border-radius: 30px;
    cursor: pointer;
    // overflow: hidden;
    // position: relative;
    display: flex;
    &:hover{
      font-size: 35px;
    }
  }

  // .eachcarouselcard::after {
  //   background: #E5E5E5;
  //   content: "";
  //   height: 200px;
  //   left: -200px;
  //   opacity: 1;
  //   position: absolute;
  //   top: -60px;
  //   transform: rotate(35deg);
  //   transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  //   width: 35px;
  //   z-index: -10;
  // }

  // .eachcarouselcard:hover::after{
  //   left: 130%;
  //   transition: all .55s cubic-bezier(.19,1,.22,1);
  //   z-index: 50;
  // }
  


  .headcardpart{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 260px;
    width: 25%;
    cursor: pointer;
    overflow: hidden;
    background-size: cover;
    border-top-left-radius: 30px;
    // border-bottom-left-radius: 30px;
  }

  .bodycardpart{
    height: 260px;
    width: 75%;
    background-color: #FFFFFF;
    color: #16171A;
    padding: 25px;
    display: flex;
    align-items: center;
    // flex-direction: column;
    // text-align: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .bodybox1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    padding: 0px 25px;
    height: calc(100% - 50px);
  }

  .bodybox2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 50px);
    width: 30%;
    padding: 0px 25px;
  }

  .newhead{
    padding: 0px;
  }

  .whitelabhead{
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 5px;
    color:#16171A;
  }

  .whitelabsubhead{
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    color:#16171A;
    padding: 0px 0px 25px 0px;
  }

  .whitelabdesc{
    height: 250px;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    color:#16171A;
  }

  .box2btn1{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    height: 50px;
    width: 200px;
    margin-bottom: 25px;
    background: #FFFFFF#16171A;
    border: 1px solid #16171A;
    border-radius: 15px;
    color: #16171A;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .box2btn1::after {
    background: #16171A;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .box2btn1:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }


  .box2btn2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    height: 50px;
    width: 200px;
    background: #16171A;
    border: 1px solid #16171A;
    border-radius: 15px;
    color: #FFFFFF;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .box2btn2::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .box2btn2:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

  .statshead{
    padding-bottom: 50px;
    display: flex;
    width:72vw;
    margin: auto;
    // padding-left: 80px;
    font-weight: 500;
    font-size: 41px;
    line-height: 75px;
    color: #16171A;
  }

  .producthead{
    padding: 50px 30px;
    display: flex;
 
    margin: auto;
    // padding-left: 80px;
    font-weight: 600;
    font-size: 47px;
    line-height: 75px;
    color: #16171A;
    display: flex;
    justify-content: space-between;
  }

  .mobstatshead{
    display: none;
  }

  
  .statSection{
    height: 700px;
    border-radius: 30px;
    background-color: #FFFFFF;
    margin: auto;
    width:72vw;
    border-left: 0.5px solid #EFEFEF;
    display: grid;
    
    grid-template-columns: 1fr 2fr;
    border-top: 0.5px solid #EFEFEF;
  }

  .leftStatBox{
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 20%;
    border-right:  0.5px solid #EFEFEF;
  }

  .rightFaqBox{
    height: 700px;
    padding: 0px 4vmin 0px 8vmin;
  }

  .rFaqtitle{
    background-color: #FFFFFF;
    font-weight: 500;
    font-size: 40px;
    color: #16171A;
    padding-top: 85px;
    padding-bottom: 20px;
  }

  .rfaqdropbox{
    height: 530px;
    width: calc(100% - 12vmin);
    overflow-y: scroll;
    background-color: #FFFFFF;
  }

  .rfaqeachdrop{
    margin: 20px 0px;
    padding: 0px 30px;
    width: 100%;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
  }

  .rfaqnavbox{
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #16171A;
    cursor: pointer;
  }

  .bgclass{
    background-image: url("../../../static/images/bgs/homeBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position:center;
}

.bgclass1{
    background-image: url("../../../static/images/bgs/bg1.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position:right;
}

.bgclass2{
    background-image: url("../../../static/images/bgs/bg2.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position:center;
}
.bgclass3{
    background-image: url("../../../static/images/bgs/bg3.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position:center;
    height: 81vh;
}

.statCard{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title{
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  // line-height: 160%;
  /* or 104px */
  
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.002em;
  
  color: #16171A;
  
  }
  .subtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    // line-height: 160%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    
    color: #16171A;
    
  }

  &:hover{
    // background-color: #16171A;
    .title{
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.3s all ease-in-out;
// color: white;
    }
    .subtitle{
      cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s all ease-in-out;
    // color: white;
    }
    
}

}

  // style={{
                //   paddingLeft: "80px",
                //   paddingRight: "0px",
                //   marginTop: "-5vh",
                // }}
.subleft{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 63vh;
  z-index: 1;
}

.mainImage{
  width: 35%;
  height: 63vh;
}

.contentSections{
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 80vh;
  
  // background-color: red;
  .textSection{
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: flex-start;
    padding:0px 80px;
    .bigTitle{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 75px;
      /* or 125% */
      display: flex;
      align-items: center;
      color: #16171A;
    }
    .smallSubtitle{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 45px;
      /* or 250% */
      display: flex;
      align-items: center;
      color: #16171A;
      padding-top: 60px;
      padding-right: 10vh;
    }
    .learnMoreButton{
      margin-top: 45px;
      background: #FFFFFF;
    border: 0.25px solid #16171A;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 21px 43px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    // display: flex;
    // align-items: center;
    // text-align: center;
    
    color: #16171A;
    }
  }
  // .imgSection{
    
  // }
}

.imgdiv{
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.imgdiv:hover{
  transform: scale(1.3);
  transition: all .3s ease-out;
}

.arrowImg{
  height: 15px;
  width: 15px;
}

.buttons{
  display: flex;
  padding-top: 28px;
}
.buttons1{
  display: flex;
  padding-top: 58px;
}



.mobilecards{
  width:100% ;
  height: 500px;
  // border: 1px solid red;
 
}

.mobilecard_content{
  display:flex; 
  width:100%;
  height:278px; 
  // border:1px solid indigo; 
  align-items:center;
  margin-bottom: 20px;
  border-bottom: 0.5px solid #EFEFEF;
}

.mobilecard_content_left{
  width: 80%;
}

.mobilecard_content_left>div:nth-child(1){
  font-weight: bold;
  font-size: 64px;
  padding-left: 20px;
}

.mobilecard_content_left>div:nth-child(2){
  padding-left: 20px;
}
.mobilecard_content_right{
  width: 20%;
}

.mobilecard_content_right>img{
  width: 41px;
  height: 41px;
  // border: 1px solid red;
}

.wholedropdownbox{
  display: flex;
  justify-content: space-between;
  width:72vw;
  margin: auto;
}

// dropdown
.dropdownContainer{
  padding: 0;
  width: 39vw;
  // height: 600px;
  // border: 1px solid red;
  // margin: auto;
  display: "";
  border-radius:30px;
  // border: 0.5px solid #E5E5E5;

}

.dropdownfirst{
  border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-top: 0.5px solid #E5E5E5;
    background-color:#FFFFFF;
    width: 100%;
    border-bottom: 0.5px solid #E5E5E5;
    border-left: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
    padding: 45px 6%;
    width: 100%;
    font-size: 30px;
    font-weight: 600;
}

.dropdownlast{
  border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    // border-top: 0.5px solid #E5E5E5;
    background-color:#FFFFFF;
    width: 100%;
    border-bottom: 0.5px solid #E5E5E5;
    border-left: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;
    padding: 45px 6%;
    width: 100%;
    font-size: 30px;
    font-weight: 600;
}
.dropdown{
  background-color:#FFFFFF;
  width: 100%;
  // height: 200px;
  border-bottom: 0.5px solid #E5E5E5;
  border-left: 0.5px solid #E5E5E5;
  border-right: 0.5px solid #E5E5E5;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // justify-content: space-around;
  // padding-left: 10%;
  padding: 45px 6%;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  
}

// .dropdown1{
//   // height: 90vh;
  
//   height: 660px;
// }
// .dropdown2{
//   height: 845px;
// }

// .dropdown3{
//   height: 1020px;
// }

// .dropdown4{
//   height: 1020px;
// }
// .dropdown>div:nth-child(1){
//   padding: 3.5% 6%;
//   width: 80%;
// }
// .dropdown>div:nth-child(2){
//   padding: 3% 6%;

//   width: 20%;
// }

.dropdownnav{
  // height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 19px;
  font-weight: 600px;
  color: #16171A;
}

.ondropdown{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdowntext{
  // padding: 3% 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #16171A;
  padding-bottom: 20px;
}

.dropdowntext1{
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #16171A;
  padding-bottom: 20px;
}

.dropdownbtn{
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  height: 70px;
  width: 200px;
  background: #16171A;
  border: 1px solid #16171A;
  border-radius: 35px;
  color: #FFFFFF;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.dropdownbtn::after {
  background: white;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all .55s cubic-bezier(.19,1,.22,1);
  width: 50px;
  z-index: -10;
}

.dropdownbtn:hover::after{
  left: 120%;
  transition: all .55s cubic-bezier(.19,1,.22,1);
  z-index: 50;
}

.mobheadtext{
  display: none;
}

.mobdrophead {
  display: none;
}

.webdrophead {
  display: flex;
}

.valueaddbox{
  background-color: #16171A;
  width: 100%;
  color: #FFFFFF;
  padding: 150px 0;
}

.valueCardbox{
  display: flex;
  margin: 0px 80px;
  height: 700px;
  width: 90vw;
  background-color: #FFFFFF;
}

.eachvaluecard{
  display: flex;
  border: 1px solid #E5E5E5;
  height: 700px;
  width: 12.11vw;
  // transform: rotate(270deg);
}

.valuetitle{
  height: 100%;
  width: 100%;
  transform: rotateY(135deg);
}

////accordion

.valsecbox {
  display: flex;
  min-height: 80vh;
  max-height: 80vh;
  width:72vw;
  margin: auto;
  border-radius: 30px;
  overflow: hidden;
  list-style-type: none;
}

.valbox1 {
    border: 0.5px solid #E5E5E5;
    flex: 0.5 1;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background: white;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 10px 10px 10px 10px;
  
  &:hover {
    background: darken(#E5E5E5, 3);
  }

  .sectiontitle {
    flex: 0.5;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    // text-align: center;
    color: #16171A;
    
    .acchead {
      font-size: 25px;
      font-weight: 600;
      margin: 0;
      color: #16171A;
      font-size: 25px;
      font-weight: 600;
      margin: 0;
      transform: rotate(180deg);
      color: #16171A;
      writing-mode: vertical-lr;
      padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      // white-space: nowrap;
    }

    .plusimg{
      height: 20px;
      width: 20px;
    }
  }
  
  .sectioncontent {
    display: none;
    align-items: center;
    opacity: 0;
  }
  
  &.active {
    flex: 3;
    background: #fff;
    cursor: default;

    .sectiontitle {
      display: none;
    }
    
    .sectioncontent {
      margin: 0;
      padding: 30px;
      flex: 0.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 3;
      opacity: 1;
      transform: scaleX(1);
      transition: all .25s .1s ease-out;
      color: #16171A;
      text-align: left;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      .acchead1 {
        font-size: 25px;
        font-weight: 600;
        margin: 20px 0px;
        color: #16171A;
        width: 100%;
        text-align: left;
        // white-space: nowrap;
      }
    }
  }
  
  
}


//// end here


.moballvalcards{
  display: none;
}









@media only screen and (min-width: 900px) and (max-width: 3000px){
  .ctaSection1{
    display: none;
  }
  .heroText1{
    display: none;
  }
  .herosubText1{
    display: none;
  }
  .mobilecards{
    display: none;
  }
  .buttons1{
    display: none;
  }
}

@media screen and (min-width: 1750px) and (max-width: 3000px) {
  .heroText{
    font-size: 65px;
  }
}

@media screen and (min-width: 1600px) and (min-height: 860px) and (max-height: 1200px){
  .square{
    height: 63vh;
  }

  .subleft{
    height: 63vh;
  }
  
  .mainImage{
    height: 63vh;
  }
  
  .imagesBox{
    height: 63vh;
  }

  .containerLeftRight{
    height: 63vh;
  }

}

.imagesBox{
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 63vh;
  width: 45%;
  transform: scaleX(1.5);
  z-index: 0;
}

.rowbox{
  display: flex;
  height: 140px;
  width: 100%;
  justify-content: end;
}

.linebox{
  height: 15px;
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: end;
}

.eachimgcircle{
  height: 80px;
  width: 80px;
  cursor: pointer;
}

.eachimgcircle1{
  height: 140px;
  width: 140px;
  cursor: pointer;
}

.eachcirclebox{
  height: 140px;
  width: 140px;
  border: 2px solid #EFEFEF;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &:hover{
    .eachimgcircle{
      transform: scale(1.15);
      transition: ease .3s;
    }
  }
}

@keyframes blink{
  0%{opacity: 0;}
  25%{opacity: .25;}
  50%{opacity: .50;}
  75%{opacity: .75;}
  100%{opacity: 1;}
  }

.eachlineh{
  height: 30px;
  width: 100%;
  animation: blink 2s linear infinite;
}

.eachlinev{
  height: 30px;
  width: 35px;
  animation: blink 2s linear infinite;
}

.eachhorzbox{
  height: 100%;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eachvertbox{
  height: 15px;
  width: 140px;
  // margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linesgap{
  margin-right: 30px;
}

.companiesBox{
  height: 28vh;
  margin: auto;
  width:72vw;
  // padding-left:80px;
}

.headerBox{
  height: 6vh;
  display: flex;
}

.companyhead{
  font-weight: 600;
  font-size: 20px;
  margin-right: 20px;
  width: 600px;
  color: #16171A;
  // height: 40px;
}

.borderbox{
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(78vw - 620px);
  margin-top: 10px;
  border-top: 2px solid #EFEFEF;
}

.allappbox{
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
}

.companyimg{
  height: 60px;
  width: 60px;
  cursor: pointer;
}

// .companyname{
//   display: none;
// }

.companyname{
  width: 120px;
  display: none;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  color: #16171A;
}

.companyeachbox{
  margin-top: 20px;
  width: 120px;
  height: 120px;

  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 150px;
height: 150px;
margin-bottom: 1rem;

background: #ffff;
border: 0.5px solid #EFEFEF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 15px;


}

.companyeachcard{
  &:hover{
  .companyeachbox{
    // margin-top: 0px;
    // transition: ease 0.3s;
  }
  .companyimg{
    transform: scale(1.15);
    transition: ease .3s;
  }
  .companyname{
    margin-top: 5px;
    width: 120px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    color: #16171A;
  }

  }
}

.alldropsBox{
  width:100%;
  color:#16171A;
  background-color: rgba(237, 237, 237, 0.37);
  padding:150px 0;
}

.modelhead1{
  font-size: 41px;
  font-weight: 500;
  padding-bottom: 50px;
  width:72vw;

  margin: auto;
  // padding-left: 80px;
  color: #16171A;
  padding-bottom: 80px;
}

.eachLeftCards1{
  padding-left: 20px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  // flex-direction: column;
  height: 100px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #16171A;
  cursor: pointer;
  border-radius: 10px;
  &:hover{
    background-color: #E5E5E5;
    // color: #FFFFFF;
  }
}

.modelimg1{
  width: 40px;
  height: 40px;
  margin: 0px 20px 0px 10px;
}

.eachLeftCards2{
  padding-left: 20px;
  background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  // flex-direction: column;
  height: 100px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
  border-radius: 10px;
}

//// table css

// .tablebox{
//   background-color: #FFFFFF;
//   margin: auto;
//   height: 70vh;
//   width:72vw;
//   display: flex;
//   flex-wrap: wrap;
//   border-radius: 30px;
//   overflow: scroll;
//   // .tableLeftBox{
//   //   width: 22vw;
//   //   border-right: 1px solid #EFEFEF;
//   //   .tableNavdiv1{
//   //     height: 100px;
//   //     border-bottom: 1px solid #EFEFEF;
//   //     display: flex;
//   //     flex-direction: column;
//   //     justify-content: center;
//   //     padding: 0px 0px 0px 50px;
//   //     .tablemainimg{
//   //       height: 50px;
//   //       width: 200px;
//   //     }
//   //   }
//   //   .tablesidebox{
//   //     height: 1180px;
//   //     // display: flex;
//   //     // flex-direction: column;
//   //     padding: 0px 0px 0px 70px;
//   //     .eachtablesidemenu{
//   //       height: 130px;
//   //       display: flex;
//   //       align-items: center;
//   //       .tablemenuimg{
//   //         height: 30px;
//   //         width: 30px;
//   //         margin-right: 10px;
//   //       }
//   //       .tablemenutitle{
//   //         font-weight: 700;
//   //         font-size: 18px;
//   //       }
//   //     }
//   //   }
//   // }
  
//   // .tableRightBox{
//   //   width: 60vw;
//   //   overflow-x: scroll;
//   //   overflow-y: hidden;
//   //   .tableNavdiv2{
//   //     display: -webkit-inline-box;
//   //     height: 100px;
//   //     // width: 60vw;
//   //     .tabmenubox1{
//   //       padding: 30px 0px;
//   //       width: 200px;
//   //       display: flex;
//   //       flex-direction: column;
//   //       justify-content: center;
//   //       text-align: center;
//   //       border-bottom: 1px solid #EFEFEF;
//   //       .tabmenuname{
//   //         display: flex;
//   //         flex-direction: column;
//   //         justify-content: center;
//   //         text-align: center;
//   //         height: 40px;
//   //         font-weight: 700;
//   //         font-size: 15px;
//   //         line-height: 25px;
//   //         color: #16171A;
//   //         border-right: 1px solid #EFEFEF;
//   //       }
//   //     }
//   //   }
//   //   .rightbody{
//   //     height: 1180px;
//   //     .allaccessdatabox{
//   //       height: 130px;
//   //       display: -webkit-inline-box;
//   //     }
//   //     .tabmenubox1{
//   //       padding: 30px 0px;
//   //       height: 130px;
//   //       width: 200px;
//   //       display: flex;
//   //       flex-direction: column;
//   //       justify-content: center;
//   //       text-align: center;
//   //       border-right: 1px solid #EFEFEF;
//   //     }
//   //   }
//   // }

//   // .tabnav{
//   //   position: sticky;
//   //   top: 0;
//   //   display: flex;
//   //   height: 100px;
//   //   width:72vw;
//   //   // overflow: hidden;
//   // }

//   // .tableNavdiv1{
//   //   height: 100px;
//   //   width: 22vw;
//   //   border-bottom: 1px solid #EFEFEF;
//   //   border-right: 1px solid #EFEFEF;
//   //   display: flex;
//   //   flex-direction: column;
//   //   justify-content: center;
//   //   padding: 0px 0px 0px 50px;
//   //   background-color: white;
//   //   border-top-left-radius: 30px;
//   //   position: sticky;
//   //   top: 0;
//   //   left:0;
//   //   .tablemainimg{
//   //     height: 50px;
//   //     width: 200px;
//   //   }
//   // }

//   // .tableNavdiv2{
//   //   border-top-right-radius: 30px;
//   //   display: -webkit-inline-box;
//   //   height: 100px;
//   //   width: 60vw;
//   //   // overflow-x: scroll;
//   //   background-color: white;
//   //   .tabmenubox1{
//   //     padding: 30px 0px;
//   //     width: 200px;
//   //     display: flex;
//   //     flex-direction: column;
//   //     justify-content: center;
//   //     text-align: center;
//   //     border-bottom: 1px solid #EFEFEF;
//   //     .tabmenuname{
//   //       display: flex;
//   //       flex-direction: column;
//   //       justify-content: center;
//   //       text-align: center;
//   //       height: 40px;
//   //       font-weight: 700;
//   //       font-size: 15px;
//   //       line-height: 25px;
//   //       color: #16171A;
//   //       border-right: 1px solid #EFEFEF;
//   //     }
//   //   }
//   // }

//   // .tabbody{
//   //   display: flex;
//   //   height: calc(70vh - 100px);
//   //   width:72vw;
//   //   // overflow-y: scroll;
//   //   // overflow-x: hidden;
//   // }

//   // .tablesidebox{
//   //   width: 22vw;
//   //   height: 1180px;
//   //   padding: 0px 0px 0px 70px;
//   //   border-right: 1px solid #EFEFEF;
//   //   position: sticky;
//   //   left: 0;
//   //   background: white;
//   //   .eachtablesidemenu{
//   //     height: 130px;
//   //     display: flex;
//   //     align-items: center;
//   //     .tablemenuimg{
//   //       height: 30px;
//   //       width: 30px;
//   //       margin-right: 10px;
//   //     }
//   //     .tablemenutitle{
//   //       font-weight: 700;
//   //       font-size: 18px;
//   //     }
//   //   }
//   // }

//   // .rightbody{
//   //   width: 60vw;
//   //   .allaccessdatabox{
//   //     height: 130px;
//   //     display: -webkit-inline-box;
//   //   }
//   //   .tabmenubox1{
//   //     padding: 30px 0px;
//   //     height: 130px;
//   //     width: 200px;
//   //     display: flex;
//   //     flex-direction: column;
//   //     justify-content: center;
//   //     text-align: center;
//   //     border-right: 1px solid #EFEFEF;
//   //   }
//   // }

  
// }

.tablebox{
  background-color: #FFFFFF;
  margin: auto;
  height: 100%;
  width:72vw;
  border-radius: 30px;
  overflow: scroll;
  position: relative;
  animation: slide-left .4s forwards;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
}

.wholetable{
  position: relative;
  border-collapse: collapse;
}


thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
}

thead th:first-child {
  left: 0;
  z-index: 1;
}

tbody th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: #FFF;
}

tr{
  height: 100px;
}

// thead{
//   border-bottom: 1px solid #EFEFEF;
// }

.columnhead{
  width: 220px;
  height: 100px;
  border-right: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.tabmenubox1{
  display: flex;
    justify-content: center;
    align-items: center;
}

.tableNavdiv1{
  width: 250px;
  border-right: 1px solid #EFEFEF;
  border-bottom: 1px solid #EFEFEF;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
}

.tablemainimg{
  height: 100px;
  width: 150px;
}

.mobtablemaintext{
  display: none;
}

.eachtablesidemenu{
  display: flex;
  padding-left: 30px;
  align-items: center;
  border-right: 1px solid #EFEFEF;
  height: 100px;
}

.tabmenubox1{
  border-right: 1px solid #EFEFEF;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tablemenuimg{
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

/// table css end here


.facingproductsbox{
  display: block;

  margin: auto;
  height: 80vh;
  background-color: #FFFFFF;
  padding-top: 13px;
  transition: all ease-in-out .3s;
}

.facingproductsboxmob{
  display: none;
}


.alltempcards{
  margin: auto;
  // padding: 0 14vw;
  width:72vw;
  height: 700px;
  display: flex;
  background-color: #FFFFFF;
 // padding-bottom: 50px;
  // border-radius: 30px;
  position: relative;
  top: 291px;
  border-bottom: 10px solid #E7E7E7;
  border-top: 1px solid #E7E7E7;
  // justify-content: space-evenly;
  // overflow: auto;
  // flex-wrap: wrap;
}


.labelarrow{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  height: 700px;
  width: 5%;
  // top: 50%;
  // position: sticky;
}

.mainalllabels{
  width: 30%;
  height: 100%;
  overflow: auto;
}

.alllabelsbox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
  overflow-y: hidden;
  // padding-top: 1160px;
}

.eachlabelsbox{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 10px;
  border-radius: 10px;
  position: relative;
  padding: 70px 0;
  // background-color: #16171A;
}

.imglabelarrow{
  height: 20px;
  width: 20px;
}

.eachitemimg{
  margin-right: 10px;
  height: 40px;
  width: 40px;
  z-index: 1;
}

.whitelabtitle{
  font-weight: 600;
  font-size: 30px;
  color: #FFFFFF;
  z-index: 1;
}

.tempdatabox{
  height: 100%;
  width: 65%;
  padding: 0px 65px 0px 45px;
  overflow: scroll;
}

.tpdsec1{
  display: flex;
  justify-content: space-between;

}

.tpdtextbox{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tpdhead{
  font-weight: 600;
  font-size: 30px;
  line-height: 25px;
  color: #16171A;
  padding-bottom: 10px;
}

.tpdsubhead{
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #16171A;
  // padding-bottom: 15px;
}

.tpdbuttonbox{
  display: flex;
  align-items: center;
}

.tpdbuttonbox{
  display: flex;
  align-items: center;
}

.tpdbtn1{
  height: 45px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  margin-right: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #16171A;
  background: #FFFFFF;
  border: 0.5px solid #16171A;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

}

.tpdbtn1::after {
  cursor: pointer;
  background: #16171A;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all .55s cubic-bezier(.19,1,.22,1);
  width: 50px;
  z-index: -10;
}

.tpdbtn1:hover::after{
  left: 120%;
  transition: all .55s cubic-bezier(.19,1,.22,1);
  z-index: 50;
}

.tpdbtn2{
  height: 45px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  font-size: 16px;
  line-height: 32px;
  color: #FFFFFF;
  background: #16171A;
  border: 0.5px solid #16171A;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.tpdbtn2::after {
  background: #FFFFFF;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all .55s cubic-bezier(.19,1,.22,1);
  width: 50px;
  z-index: -10;
}

.tpdbtn2:hover::after{
  left: 120%;
  transition: all .55s cubic-bezier(.19,1,.22,1);
  z-index: 50;
}

.tpddesc{
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  color: #16171A;
  padding: 40px 0px;
}



.tpdeachdrop{
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #16171A;
  padding: 35px 0px;
  border-top: 1px solid #EFEFEF;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover{
    transform: scale(1.05);
    transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
  }
}

.tpdeachdrop1{
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #16171A;
  padding: 40px 0px;
  border-top: 1px solid #EFEFEF;
  display: flex;
  justify-content: space-between;
  // &:hover{
  //   transform: scale(1.05);
  //   transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
  // }
}

.tpdeachdropimg{
  height: 20px;
  width: 20px;
 
}

.tpdstatsbox{
  // display: flex;
  // justify-content: space-between;
  padding: 20px 0px;
}

.tpdstatsminibox{
  margin-bottom: 20px;
}

.tpdstatsboxTitle{
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-decoration-line: underline;
  color: #16171A;
}

.tpdeachstatrow{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tpdeachstathead{
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: #16171A;
  padding-bottom: 10px;
}

.tpdeachstatsubhead{
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #16171A;
}

.tpdeachstat{
  width: 45%;
  padding: 15px 20px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 25px;
  margin: 15px 0px;
  transition: all ease .8s;
  &:hover{
    padding-left: 5%;
    background-color: #16171A;
    .tpdeachstathead, .tpdeachstatsubhead{
      color: white;
    }
  }
}



.togbox{
  padding-top: 20px;
  display: flex;
  height: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #16171A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop1title{
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  color: #16171A;
  margin-bottom: 30px;
}

.drop1desc{
  margin-left: 45px;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  color: #16171A;
  display: flex;
  margin-bottom: 30px;
}

.tempdrop1img{
  height: 15px;
  width: 15px;
  margin-right: 15px;
}

.alldrop2cards{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.drop2box{
  width: 48%;
  // height: 200px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 25px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 19px;
  line-height: 30px;
  color: #16171A;
}

.tpdeachdrop2img{
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.drop2desc{
  margin-top: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #16171A;
}

.tpdheadbox{
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FFFFFF;
  padding-top: 78px;

}

.mobstatshead1, .companymobhead{
  display: none;
}

.mobeachRightCards{
  display: none !important;
}








































































@media only screen and (min-width: 0px) and (max-width: 900px){
  .mobintegratedLeftCards{
    justify-content: space-between;
}
.eachLeftCards2{
  background: white;
  border: 0px solid #16171A !important;
}
.eachdescbox{
border: 0px solid !important;
}
.box5 {
  cursor: pointer;
  height: 7vh;
  width: 511vw;

}
.eachProvideCard {
  height: 26rem;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 26px;
}
.provideHead {
  font-weight: 600;
  font-size: 23px;
  line-height: 32px;

}
.provideDesc {
  font-weight: 300;
  font-size: 13px;
  color: #16171A;
  line-height: 32px;
  padding-bottom: 45px;
}
.provideimg {
  height: 70px;
  width: 70px;
}
.providesubHead{
  font-weight: 500;
  font-size: 22px;
  color: #16171A;
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #16171A;
}
  .companyeachbox{
    margin-top: 20px;
    width: 120px;
    height: 120px;
  
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100px;
  height: 100px;
  margin-bottom: 1rem;
  
  background: #FFFFFF;
  
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  }
  .mobeachRightCards{
    display: block !important;
    margin: 0 40px;
    padding: 120px 0px 0px 0px;
    height: 100vh;
  }

  .mobbackbtn{
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    text-decoration-line: underline;
    color: #16171A;
    margin-bottom: 20px;
    &:hover{
      transform: scale(1.1);
      transition: all ease .3s;
    }
  }

  .mobrightTitle{
    font-weight: 600;
    font-size: 22px;
    line-height: 40px;
    color: #16171A;
    margin-bottom: 20px;
  }

  .mobvideobox{
    // margin-top: 20px;
    width: 100%;
    border-radius: 20px;
    height: 300px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
 
      .mobvideoheadbox{
        background-image: url('../../../static/images/about/blurback.svg');
        border-radius: 20px;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
      }
  }

  // .mobvideoheadbox{
  //   display: none;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }

  .mobdescribeleft{
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #16171A;
  }

  .mobvideotitle{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-left: 10px;
  }

  .mobyouimg{
    width: 65px;
    height: 45px;
    margin-bottom: 20px;
  }

  .mobrightDesc{
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    color: #16171A;
    margin-bottom: 20px;
  }

  .columnhead{
    font-size: 15px;
  }

  .producthead{
    display: none;
  }

  .facingproductsbox{
    display: none;
  }

  .facingproductsboxmob{
    display: block;
    width: 100vw;
    height: auto;
    border-radius: 0px;
    border-top: 1px solid #E5E5E5;
  }


  .statSection{
    display: none;
  }

  .statSectionmob{
    display: block;
    background-color: #FFFFFF
  }

  .empowerhead{
    display: none;
  }
  .empowerheadmob{
    color: #FFFFFF;
    display: flex;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    text-align: left;
    width: auto;
    z-index: 1;
  }
  .empowerbtn{
    margin-top: 40px;
    cursor: pointer;
    height: 7vh;
    width: 13rem;
  
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 1.5px;
      background: linear-gradient(45deg, #277EFB, #B580F2);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
  }
  }

  .statCard{
    height: 200px;
    width: 100vw;
    display: none;
    align-items: self-start;
    padding-left: 20px;;

    .title{
      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      color: #16171A;

    }
  }

  .rFaqtitle{
    font-size: 36px;
    text-align: left;
    padding-left: 20px;
  }

  .rfaqdropbox{
    height: auto; 
    width: 100vw;
    overflow: hidden;
  }
  
  .rfaqeachdrop{
    margin: 0px;
    padding: 0px 20px;
    border-radius: 0px;
    border-top: 0.5px solid #E5E5E5;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .rfaqnavbox{
    font-weight: 600;
  }

  .scrollNav{
    display: none !important;
  }

  .gdbox{
    display: block;
  }

  .gdbox1{
    display: block;
    width: 100%;
    margin: 0px;
  }


  .containerLeftRight{
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
  }

  .imagesBox{
    height: auto;
    width: 90vw;
    margin: 0 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scaleX(1);
  }

  .rowbox{
    display: flex;
    height: 100px;
    width: 100%;
    justify-content: center;
  }
  
  .linebox{
    height: 15px;
    width: 100%;
    justify-content: center;
    display: flex;
    overflow: hidden;
  }
  
  .eachimgcircle{
    height: 50px;
    width: 50px;
    cursor: pointer;
  }
  
  .eachimgcircle1{
    height: 100px;
    width: 100px;
    cursor: pointer;
  }
  
  .eachcirclebox{
    height: 100px;
    width: 100px;
    border: 2px solid #EFEFEF;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &:hover{
      .eachimgcircle{
        transform: scale(1.15);
        transition: ease .3s;
      }
    }
  }
  
  @keyframes blink{
    0%{opacity: 0;}
    25%{opacity: .25;}
    50%{opacity: .50;}
    75%{opacity: .75;}
    100%{opacity: 1;}
    }
  
  .eachlineh{
    height: 30px;
    width: 90%;
    animation: blink 2s linear infinite;
  }
  
  .eachlinev{
    height: 30px;
    width: 35px;
    animation: blink 2s linear infinite;
  }
  
  .eachhorzbox{
    height: 90%;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .eachvertbox{
    height: 15px;
    width: 130px;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .linesgap{
    display: none;
  }
  

  .statshead{
    display: none;
  }

  .mobstatshead, .mobstatshead1{
    padding: 0px 20px 40px 20px;
    text-align: left;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #16171A;
    display: none;

  }

  .tablebox{
    height: auto;
    width: 100vw;
    border-radius: 0px;
  }

  .mobstatshead1{
    display: block;
    padding-top: 60px;
  }
  
  .webdrophead {
    display: none;
  }

  .mobdrophead {
    display: flex;
  }

  .headtext{
    display: none;
  }

  .mobheadtext{
    text-align: center;
    display: flex;
    font-weight: 600;
    font-size: 31px;
    line-height: 40px;
    padding-bottom: 28px;
    color: #FFFFFF;
    z-index: 9;
    justify-content: flex-start;
    text-align: left;
  }

  .box1{
    display: none;
  }

  .box4{
    display: none;
  }

  .box5{
    cursor: pointer;
    height: 7vh;
    width: 60vw;
    // border: 1px solid #FFFFFF;
    border-radius: 40px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 1.5px;
      background: linear-gradient(45deg, #277EFB, #B580F2);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
  }
  }

  .box5::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .box5:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }

  .mainImage{
    display: none;
  }

  .dropdownnav{
    font-size: 18px;
    color: #16171A;
  }

  .dropdowntext{
    font-size: 14px;
    line-height: 35px;
    // padding-top: 40px;
  }

  .dropdowntext1{
    font-size: 14px;
    line-height: 35px;
  }

  .dropdown{
    font-weight: 600;
    font-size: 18px;
    padding: 50px 6%;
    // height: 120px;
  }

  .dropdownbtn{
    margin-top: 40px;
    height: 40px;
    width: 120px;
    font-size: 12px;
  }

  .imgdiv{
    height: 8px;
    width: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .imgdiv:hover{

    transform: scale(1.1);
    // transition: all ease 0.3s;
    transition: all .3s ease-out;
  }

  .arrowImg{
    height: 8px;
    width: 12px;
  }

 
  
  .ctaSection{
    display: none;
  }
  .about_heroText__hcRxe{
    width: 100vh;
  }
  .ctaSection1{
    margin-top: 60px;
    width: 100vw;
    bottom: 0;
    position: sticky;
    background: #16171A;
    color: white;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  // line-height: 45px;
  /* or 250% */
  display: flex;
  align-items: center;
  text-align: center;
  
  color: #FFFFFF;
  // @include btn-animate(#FFFFFF);
  &:hover{  
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.3s all ease-in-out;
  }
  }
  .heroText{
    width: 100vw;
    font-size: 20px;
    // font-family: 'Montserrat';
    // font-style: normal;
    // font-weight: 600;
    
    // line-height: 130%;
    // /* or 56px */
    
    // display: flex;
    // align-items: center;
    // // letter-spacing: 0.002em;
    
    // color: #16171A;
  }
  .heroText1{
    margin-top: 60px;
    width: 100vw;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 31px;
    line-height: 45px;
    color: #16171A;
    padding-bottom: 10px;
  }
  .herosubText1{
    width: 100vw;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #16171A;
  }
  .subHeroText{
    padding: 0px 40px;
    margin: 0px;
    width: 100vw;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
  }
  .heroText{
    display: none;
  }
  .herosubText{
    display: none;
  }
  .subleft{
    padding: 0px;
    margin: 20px 0px 20px 0px;
    height: auto;
    width: 100vw;
  }
  .buttons{
   display: none;
  }
  .buttons1{
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
    align-items: center;
    border-radius: 0;
    // width: 64vw;
  }

  .companiesBox{
    height: auto;
    width: 100vw;
    padding: 30px 11vw;
    border-top: 0.5px solid #EBEBEB;
    .companyhead, .borderbox{
      display: none;
    }
    .companymobhead{
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 45px;
      color: #16171A;
    }

  }

  .bgclass{
    display: none;
  }
  .mobilecards{
   
    
    width:100% ;
    height: 1600px;
    border-top: 0.5px solid #EFEFEF;
  }

  .square{
    height: auto;
   width: auto;
  }
  
 .contentSections{
  display: grid;
  margin-top: 170px;
  height: initial;
  .textSection{
    grid-row: 2;
    padding: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
    .bigTitle{
      font-weight: 600;
      font-size: 35px;
      line-height: 56px;
      text-align: left;
      padding: 0 30px;
      width: 100vw;
      display: flex;
      padding-top: 38px;
      // justify-content: center;
     }
     .smallSubtitle{
      margin-top: 30px;
      font-weight: 300;
      font-size: 16px;
      line-height: 42px;
      text-align: left;
      padding: 0 30px;
      width: 100vw;
      display: flex;
      // justify-content: center;
     }
   }
 }
 
 .bgclass1{
  width: 100vw;
  height: 262px;
 }
 .bgclass3{
  width: 100vw;
  height: 262px;
 }
 .bgclass2{
  width: 100vw;
  height: 262px;
 }
 .gdbox1 {
  padding: 0px 0;
 }
  .requestBox{
    height: auto;
    width: 100vw;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5vw;
    padding-left: 20px;
    padding-right: 20px;
  }

  .reqinnerbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .modelbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    background-color: rgba(237, 237, 237, 0.37);
    padding: 60px 20px 10px 20px;
  }

  .modelhead1{
    font-weight: 500;
    font-size: 34px;
    line-height: 50px;
    padding: 0px 0px 60px 0px;
    text-align: left;
    width: 100%;
    padding-bottom: 0rem;
  }

  .integratedbox{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    height: auto !important;
  }

  .integratedLeftCards{
    display: none !important;
  }

  .mobintegratedLeftCards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .eachdescbox{
      // height: 200px;
      width: 42%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
      text-align: center;
      margin-right: 20px;
      margin-bottom: 40px;
      .eachLeftCards2{
        width: 150px;
        height: 150px;
        padding: 0;
        justify-content: center;
        border: 1px solid #16171A;
      }
      .eachLeftCards1{
        padding: 0;
        width: 150px;
        height: 150px;
        justify-content: center;
        border: 1px solid #E5E5E5;
      }
      .modelimg1{
        margin: 0px;
      }
    }
  }

  .webintegratedLeftCards{
    display: none !important;
  }

  .closebox{
    display: none !important;
  }

  .eachRightCards{
    display: none !important;
  }

  .alldropsBox{
    width:100%;
    color: #16171A;
    background-color: rgba(237, 237, 237, 0.37);
    padding:60px 0;
  }

  .wholedropdownbox{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .dropdownContainer{
    width: 100%;
    border-radius: 0px;
  }

  // .dropdown1{    
  //   height: 600px;
  // }
  // .dropdown2{
  //   height: 900px;
  // }
  
  // .dropdown3{
  //   height: 600px;
  // }
  
  // .dropdown4{
  //   height: 950px;
  // }

  .alltempcards1{
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    .headcardpart{
      width: 100%;
      height: 150px;
    }
    .eachcarouselcard1{
      margin: 30px 10px;
      width:95%;
      height: 500px;
      background-size: cover;
      font-weight: 700;
      font-size: 30px;
      line-height: 25px;
      color: #FFFFFF;
      border-radius: 30px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      &:hover{
        // font-size: 35px;
        .headcardpart{
          transform: scale(1.3);
          transition: ease .3s;
        }
      }
    }

    
    .bodycardpart{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 400px;
      text-align: center;
      border-top-right-radius: 0px;
    }
  }

  .statbox{
    width: 100%;
    background-color: rgba(237, 237, 237, 0.37);
    padding: 0px 0;
  }

  .alltempcards{
   display: none;
  }



  // .statCard{
  //   width: 50%;
  //   text-align: center;
  //   .title{
  //     font-weight: 700;
  //     font-size: 35px;
  //   }
  //   .subtitle{
  //     font-size: 15px;
  //   }
  // }

  .templatebox{
    padding: 60px 0;
  }

  .tempHead{
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    padding: 0px 0px 40px 0px;
    text-align: left;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

 

  .productbox{
  
  }

  .menucard{
    width: 100%;
    margin: 0px 0px 30px 0px;
  }

  .empowercard{
    height: auto;
    padding: 60px 20px;
  }

  .empbox{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }

  // .empowerhead{
  //   font-weight: 600;
  //   font-size: 40px;
  //   line-height: 50px;
  //   text-align: center;
  //   width: auto;
  // }

  .empowertext{
    padding-top: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    width: auto;
  }

 

  .valueaddbox{
    padding: 60px 0;
  }

  .valsecbox{
    display: none;
  }

  .moballvalcards{
    display: block;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    align-items: center;
    padding: 20px 20px 20px 20px;
  }

  .eachmenu {
    padding: 40px 20px;
    margin-top: 20px;
    width: 100%;
    margin-right: 2%;
    background: #252B32;
    border: 0.5px solid #252B32;
    border-radius: 15px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .menuhead1 {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: #ffff;
  }

  .menudesc1 {
    font-weight: 300;
    font-size: 12px;
    opacity: 0;
    line-height: 28px;
    color: #16171A;
  }
  .check1text {
    display: none;
}

.cardimagebox {
  display: flex;
  justify-content: space-between;
}

.zoom1 {
  background: #f3f4f5;
  border: 0;
  border-radius: 30px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-block;
  height: 30px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  background: linear-gradient(180deg, #277EFB 0%, #B580F2 100%);
  width: 30px;
}
.clickarrow-img {
  height: 20px;
  width: 20px;
}
}

.eachmenu:hover{
  .check1text{
    color: #fff;
    display: inline-flex;
    margin-left: 9px;
    margin-top: 5px;
    font-size: 15px;
    font-weight: 700;
    }
    .clickarrowimg{
        margin-left: 0.2rem;
        margin-top: 0.3rem;
        width: 20px;
    }

    .zoom1{
        // background: #fffbf0;
        height: 45px;
        text-align: center;
        width: 120px;
        outline: none;
        background: linear-gradient(180deg, #277EFB 0%, #B580F2 100%);
        span{
          color: white !important;
        }
    }
    .icon1img{
        margin-top: 0.3rem;
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        transition: all .3s ease-in-out;        
    }
    box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
}

.provideCards{
  margin: auto;
  width: 100%;
  // display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.eachProvideCard{
  width: 100%;
  margin-bottom: 60px;
}

.eachtablesidemenu{
  padding-left: 40px;
  font-size: 18px;
}

// .tablemenutitle{
//   display: none;
// }


.tablemainimg{
  display: none;
}

.mobtablemaintext{
  display: flex;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: #16171A;
}

.tableNavdiv1{
  width: 220px;
  padding-left: 40px;
}
  
  
 
}


@media screen and (min-width:900px) and (max-width: 1250px){
  .heroText{
    font-size: 35px;
    line-height: 35px;
  }

  .herosubText{
    font-size: 25px;
    line-height: 35px;
  }
  .subHeroText{
    font-size: 13px;
    line-height: 35px;
    padding-top: 15px !important;
  }

  .requestDemoButton, .watchVideoButton{
    width: 172px !important;
    height: 40px !important;
  }
  .eachcirclebox{
    height: 80px;
    width: 80px;
  }

  .eachimgcircle{
    height: 40px;
    width: 40px;
  }

  .eachimgcircle1{
    height: 80px;
    width: 80px;
  }

  .eachvertbox{
    width: 80px;
  }
  .rowbox{
    height: 80px;
  }

  .companyeachbox{
    height: 80px;
    width: 80px;
  }

  .companyimg{
    height: 40px;
    width: 40px;
  }

  .headtext{
    font-size: 35px;
    line-height: 55px;
  }

  .box4, .companyhead{
    font-size: 13px;
  }

  .box2btn1, .box2btn2{
    width: 140px;
    font-size: 14px;
  }

  .whitelabdesc{
    font-size: 12px;
  }

  .menuImg{
    height: 70px;
    width: 70px;
  }

  .menuTitle{
    font-size: 14px;
  }

  .menuTitle1{
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

  .menudesc1{
    font-size: 10px !important;
    line-height: 22px !important;
  }

  .modelhead1, .statshead, .tempHead, .empowerhead{
    font-size: 35px;
  }

  .empowertext, .providesubHead{
    font-size: 16px;
  }

  .dropdowntext, .dropdowntext1{
    font-size: 14px;
  }

  .provideHead{
    font-size: 30px;
  }

  .provideDesc{
    font-size: 11px;
  }

  .provideimg{
    width: 60px;
    height: 60px;
  }

  .modelbox,  .templatebox, .valueaddbox, .statbox, .alldropsBox{
    padding: 100px 0px;
  }

  .eachitemimg{
    height: 20px;
    width: 20px;
  }

  .eachcarouselcardfirst, .eachcarouselcard{
    font-size: 20px;
  }

  .sectioncontent{
    font-size: 13px !important;
  }

  .acchead, .acchead1{
    font-size: 18px !important;
  }

  .title{
    font-size: 45px !important;
  }

}









.infinite-scroll-loop {
  &-outer {
      position: relative;
      &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
              #fff 0%,
              rgba(255, 255, 255, 0) calc(15%),
              rgba(255, 255, 255, 0) calc(85%),
              #fff 100%
          );
          pointer-events: none;
      }
  }
  &-inner {
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
          display: none;
      }
  }
}