.anchor{
  text-decoration: none;
}
.footerTitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 2.6vh;
    font-weight: 800;
    line-height: 25px;
  /* or 139% */
  
  display: flex;
  align-items: center;
  
  color: #16171A;
  padding-bottom: 10px;
  }
  
  .footerMenu{
    font-family: 'Montserrat';
  font-style: normal;
  font-size: 1.8vh;
  font-weight: 500;
  line-height: 25px;
  /* or 208% */
  
  display: flex;
  align-items: center;
  
  color: #16171A;
  // padding-bottom:10px;
  &:hover{
    cursor: pointer;
    transform: scale(1.01);
    transition: 0.2s all ease-in-out;
    font-weight: 700;
  }
    
    
    // &:hover{
    //   cursor: pointer;
    //   overflow: hidden;
    //   position: relative;
    //   transition: all .4s ease;
    // }
  }
  .footerMenuImg{
    height: 3vh;
    width: 3vh;
    margin-right: 20px;
    &:hover{
      cursor: pointer;
      transform: scale(1.03);
      transition: 0.3s all ease-in-out;
  }
  }
  .bottomText{
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* or 167% */
  
  display: flex;
  align-items: center;
  text-align: center;
  color: #16171A;
  &:hover{
    cursor: pointer;
    transform: scale(1.01);
    transition: 0.3s all ease-in-out;
  }
  }
  .footermob{
    height: 450px;
    display: grid;
    grid-template-columns: 3fr 3fr 6fr ;
    padding: 60px 0px;
    width:74vw;
    margin: auto;
    height: 60vh;
   }
   .mobfootersection{
    padding: 30px 0px;
    height: 100%;
    .engagebox{
      margin-left: 10%;
      .text{
        margin: 100px 0px 35px 0px;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
      }
      .eachmedia{
        display: flex;
        align-items: center;
        border-radius: 10px;
        margin: 20px 0px;
        width: 50%;
        .mediaImg{
          display: flex;
          flex-direction: column;
          justify-content: center;
          // margin-top: 15px;
          height: 20px;
          width: 20px;
          margin-right: 15px;
        }
        .mediaName{
          font-weight: 600;
          font-size: 15px;
          line-height: 25px;
        }
      }
      .eachmedia:active{
        // background-color: #F2A900;
        color: white;
      }
      .servicedata{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .servicecollection{
          font-size: 13px;
          font-weight: 600;
          line-height: 22px;
          width: 50%;
        }
        .servicecollection > div{
          margin: 20px 0px;
        }
      }
    }
  }
  .mobrightssection{
    .rightsbox{
      margin-top: 10px;
      // height: 50px;
      border-top: 0.5px solid #E5E5E5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 13px;
      line-height: 25px;
    }
  }

  .arrowimgbox:hover{
    transform: scale(1.3);
    transition: all ease .3s;
    cursor: pointer;
  }

  .arrowimg{
    height: 12px;
    width: 20px;
  }

  .arrowimgmob{
    height: 8px;
    width: 10px;
  }

   @media only screen and (min-width: 0px) and (max-width: 900px){
    .footermobcontainer{
      display: none;
    }
    .mobfootercont{
        padding-bottom: 0px;
    }
   }

   @media only screen and (min-width: 900px) and (max-width: 3000px){
    .mobfootersection{
      display: none;
    }
    .mobrightssection{
      display: none;
    }
   }

   @media screen and (min-width:900px) and (max-width: 1250px){
    .footermob{
      height: auto;
    }
   }