.PricingScroll{
    height: 100vh;
    overflow: scroll;
}
.priceingSecton{
    height: auto;
    .react-switch{
        margin-left: 10px;
        margin-right: 10px;
    }
    .react-switch-bg{
        background: rgb(136, 136, 136) !important;
        border: 4px solid #fff !important;
    }
    
    .react-switch-handle{
        background:linear-gradient(275deg, #277EFB 7.36%, #B580F2 140.3%) !important;
    }
    
        .annual{
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            /* line-height: normal; */
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2.5rem;
        }


.innerpriceseactiom{
    height: 637.754px;
    background: #16171A ;
    padding-top: 176px;



h1{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 65px; /* 130% */
margin-bottom: 2rem;
}

h2{
    color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: 35px; /* 140% */
}

}
.bottomSection{
    margin-top: 4rem;
}
.greySection{
    background: rgba(237, 237, 237, 0.37);
}
.boxSection
{
    padding: 1.3%;
    border-radius: 25px;
    background: #FFF;
    margin: 0px 14vw;
    position: relative;
    top: -9rem;
    background: white;
    padding-top: 1.7%;
    padding-bottom: 1.7%;
}
.innerbox{

}
.list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.listcustom{
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    text-align:center;
    padding-bottom:1px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    background: /* gradient can be an image */
      linear-gradient(
        to left, 
       #277EFB 0%,
        #277EFB 12%,
        #277EFB 47%,
        #B580F2 100%
      )
      left 
      bottom
      #fff    
      no-repeat; 
    background-size:100% 1px;
}
.text{
    color: #16171A;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.customitemlist{
    
background: #FFF;
width: 32%;
display: flex;
align-items: center;
justify-content: center;
}



.customitem{
    border-radius: 10px;
border: 1px solid #EFEFEF;
background: #FFF;
width: 32%;
padding-left: 1.3%;
padding-right: 1.3%;
}

h3{
    font-family: Montserrat;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: var(--Marketsverse, linear-gradient(275deg, #277EFB 7.36%, #B580F2 140.3%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-top: 1.45rem;

}

h5{
    color: #16171A;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.divfeacture{
    color: #16171A;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.itemdulicate{
    display: flex;
    width: 403px;

align-items: flex-end;
height: auto;

}
.item{
    display: flex;
    width: 403px;

align-items: flex-end;
height: 174px;

}
.customsectiionlist{
    width: calc( 100% -  403px);
    display: flex;
    align-items: center;
    justify-content:space-between;

}

label{
    background: linear-gradient(275deg, #277EFB 7.36%, #B580F2 140.3%);
opacity: 0.9;color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: normal;
display: flex;
border-radius: 50px;
margin-bottom: 0.3rem;

flex-direction: column;
justify-content: center;
flex-shrink: 0;
width: 100%;
height: 34.863px;
flex-shrink: 0;
margin-bottom:1.4rem;

&:hover{
    transform: scale(1.03);
}

}
}