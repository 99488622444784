@import "../../static/scss/colors.scss";
@import "../../static/scss/mixin.scss";

$primary-color : #16171A;

.overlayClose1 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #595959;
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
  // background-color: #656565 !important;
  // z-index: 9999999;
  opacity: 0.7;
  //  pointer-events: none !important;

}

.overlayClose{
  background-color: rgba(0,0,0,.75);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden!important;
  position: fixed!important;
  right: 0;
  top: 0;
  z-index: 5;
  // z-index: 11;
}

.overlay{
//  z-index: 0;
}


.no_pointer{
  pointer-events: none !important;
}


.searchBox {
  width: 100%;
  height: 65px;
  // background: #FFFFFF;
  // border: 0.5px solid #E7E7E7;
  // border-radius: 15px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  
  color: $primary-color
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 160%;
/* or 26px */

display: flex;
align-items: center;
letter-spacing: 0.002em;

color: #16171A;

opacity: 0.25;
}

.loginButton {
  width: 130px;
  height: 43px;
  background: #FFFFFF;
  border: 0.5px solid #E7E7E7;
  border-radius: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.002em;

  color: #16171A;
  @include btn-animate($primary-color);
}

.loginButtonMobile {
  width: 130px;
  height: 43px;
  background: #FFFFFF;
  border: 0.5px solid #E7E7E7;
  border-radius: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.002em;

  color:  $primary-color;
  @include btn-animate(#16171A);
}

.registerButton {
  width: 130px;
  height: 43px;
  background:  $primary-color;
  border: 0.5px solid #E7E7E7;
  border-radius: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.002em;

  color: #FFFFFF !important;
  @include btn-animate(white);
}

.navItems{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 160%;
/* or 22px */

display: flex;
align-items: center;
justify-content: center;
letter-spacing: 0.01em;

color: #16171A;
&:hover{
  pointer-events: auto;
  cursor: pointer;
  // background-color: rgba(24, 25, 29, 0.1);
  /* color: white; */
  -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
    transition: 0.3s all ease-in-out;
    opacity: 1 !important;
}
}
.heroText{
font-family: 'Montserrat';
font-style: normal;
font-weight: 800;
font-size: 3vw;
line-height: 130%;
/* or 56px */

display: flex;
align-items: center;
// letter-spacing: 0.002em;

color: #16171A;
}

.subHeroText{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 300;
font-size: 1vw;
line-height: 35px;
/* or 250% */

display: flex;
align-items: center;

color: #16171A;
}

.statCard{
  .title{
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  // line-height: 160%;
  /* or 104px */
  
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.002em;
  
  color: #16171A;
  
  }
  .subtitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    // line-height: 160%;
    /* or 26px */
    
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    
    color: #16171A;
    
  }

  &:hover{
    // background-color: #16171A;
    .title{
      cursor: pointer;
      transform: scale(1.2);
      transition: 0.3s all ease-in-out;
// color: white;
    }
    .subtitle{
      cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s all ease-in-out;
    // color: white;
    }
    
}

}

.title{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 85px;
// line-height: 160%;
/* or 104px */

display: flex;
align-items: center;
text-align: right;
letter-spacing: 0.002em;

color: #16171A;

}
.subtitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  // line-height: 160%;
  /* or 26px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  
  color: #16171A;
  
}

.titleMobile{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 40px;
// line-height: 160%;
/* or 104px */
// color: #16171A;
display: flex;
align-items: center;
text-align: right;
letter-spacing: 0.002em;
// color: #16171A;

// color: #FFFFFF;
}
.subtitleMobile{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  // line-height: 160%;
  /* or 26px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  
  // color: #16171A;
  // color: #16171A;
  
}

.tooltipWrapper{
  position: relative;
 &:hover{
  pointer-events: auto;
  cursor: pointer;
  background-color: rgba(24, 25, 29, 0.1);
  /* color: white; */
  -ms-transform: scale(1.02); /* IE 9 */
    -webkit-transform: scale(1.02); /* Safari 3-8 */
    transform: scale(1.02); 
    transition: 0.3s all ease-in-out;
    opacity: 1 !important;
 }
  .tooltip{
    visibility: hidden;
  }
 
}
.tooltipVisible{
  visibility: visible;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 60px;
  background-color: white;
  border: 0.5px solid #E5E5E5;
  padding:10px;
  border-radius: 5px;
}
.tooltipHidden{
  visibility: hidden;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 60px;
  background-color: white;
  border: 0.5px solid #E5E5E5;
  padding:10px;
  border-radius: 5px;
}

.tooltipVisible1{
  visibility: visible;
  position: absolute;
  
  width: 370px;
  height: 100%;
  left:0px;
  top: 0px;
  
  background: #FFFFFF;
  // border: 0.5px solid #E5E5E5;
  // border-radius: 15px;
  
}

.tooltipHidden1{
  visibility: hidden;
  position: absolute;

  
width: 560px;
height: 717px;
left: 39px;
top: 60px;

background: #FFFFFF;
border: 0.5px solid #E5E5E5;
border-radius: 15px;

}



.tooltipVisible2{
  visibility: visible;
  position: absolute; 
  width: 500px;
  height: 547px;
  right: 39px;
  top: 60px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 15px; 
}

.tooltipHidden2{
  visibility: hidden;
  position: absolute;
  width: 500px;
  height: 547px;
  right: 39px;
  top: 60px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 15px;
}

.tooltipVisible3{
  visibility: visible;
  position: absolute; 
  width: 500px;
  height: 800px;
  right: 25px;
  top: 60px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 15px; 
}

.tooltipHidden3{
  visibility: hidden;
  position: absolute;
  width: 500px;
  height: 800px;
  right: 25px;
  top: 60px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 15px;
}


.videoModalVisible{
  visibility: visible;
  position: absolute; 
  width: 560;
  height: 315;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 8px; 
  display: flex;
  justify-content: center;
  align-items: center;
  // .modalCloseButton{
  //   width: 30px;
  //   height: 30px;
  //   border-radius: 50%;
  //   background: white;
  //   position: absolute;
  //   right: -10;
  //   top: -10;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   cursor: pointer;
  // }
}
.videoModalHidden{
  visibility: hidden;
  
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0.5px solid #E5E5E5;
  padding:10px;
  border-radius: 5px;
}


.filter{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  pointer-events: none !important;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    pointer-events: none !important;
    opacity: 0.5;
  }
}


.topsection{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid #E5E5E5;
}

.menuHover{
  // border:0.5px solid white;
  &:hover{
  // border:0.5px solid #E5E5E5;
  // border-radius: 10px;
    pointer-events: auto;
    cursor: pointer;
    // background-color: rgba(24, 25, 29, 0.1);
    /* color: white; */
    -ms-transform: scale(1.05); /* IE 9 */
      -webkit-transform: scale(1.05); /* Safari 3-8 */
      transform: scale(1.05); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
  }
}

.grid{
  z-index: inherit;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height:auto;
  padding:0px;
  grid-gap: 0ch;
  border: 0.5px solid #E5E5E5;
  & > div { 
   
    display: flex;
    align-items: center;
      justify-content: center;
      height: 67px;
     border-right: 0.5px solid #E5E5E5;

     &:nth-child(9){
      background-color: #16171A;
      color: white;
      border-right: none;
      height: 100.79%;
      width: 100.3%;
      &:hover{
        background-color: #16171A !important;
        color: white !important;
      }
    }
    &:nth-child(n+3){
     
      &:hover{
        pointer-events: auto;
        cursor: pointer;
        background-color: rgba(24, 25, 29, 0.1);
        /* color: white; */
        -ms-transform: scale(1.01); /* IE 9 */
          -webkit-transform: scale(1.01); /* Safari 3-8 */
          transform: scale(1.01); 
          transition: 0.2s all ease-in-out;
          opacity: 1 !important;
          color: #16171A;
      }
    }
  }
  
  
}
.gridNoBorder{
  z-index: inherit;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height:auto;
  padding:0px;
  grid-gap: 0ch;
  & > div { 
    display: flex;
    align-items: center;
      justify-content: center;
      height: 67px;
     border: 0.5px solid rgba(229, 229, 229, 0.04);  
    &:nth-child(9){
      // background-color: #656565 !important;
      background-color: #16171A;
      color: white;
      opacity: 0.4;
    }
  }
  
}


.gridTwo{
  z-index: inherit;
  display: grid;
  grid-template-columns: 1.1fr 9.9fr 1.1fr;
  height:auto;
  padding:0px;
  grid-gap: 0ch;
  border-right: 0.5px solid #E5E5E5;
  & > div {
    display: flex;
    align-items: center;
      justify-content: center;
      height: 40px;
   }  
}

.gridThree{
  z-index: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height:auto;
  padding:0px;
  grid-gap: 0ch;
  & > div { 
    display: flex;
    align-items: center;
      justify-content: center;
      height: 67px;
     border: 0.5px solid rgba(229, 229, 229, 0.04);  
    &:nth-child(8){
      // background-color: #656565 !important;
      background-color: #16171A;
      color: white;
      opacity: 0.4;
    }
  }
  
}

.bg{
  height: 50vh;
  background:  -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8))),url('../../static/images/bgs/homeBg.png') no-repeat;
  background-size: contain;
  background-position: right;
}

.gridMobile{
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 0.5px solid #E5E5E5;
  height: 21vh;
  
  & > div {
    border: 0.5px solid #E5E5E5;
    color: #16171A;
    &:hover{
      background: #16171A;
      color: white;
    }
   }
  
}
.mobileMenuItem{
  height: 70px;
  font-weight: 700;
  color: #16171A;
}

.coinInput{
  width: 100%;
  height: 65px;
  background: #FFFFFF;
  border: 0.5px solid #E7E7E7;
  border-radius: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  padding-left: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  
  color: #16171A;
}

.requestDemoButton{
  background: #16171A;
opacity: 0.9;
border: 0.5px solid #16171A;
border-radius: 15px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 1vw;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;
@include btn-animate(#FFFFFF);


}

.watchVideoButton{
  // background: #FFFFFF;
opacity: 0.9;
border: 0.5px solid #16171A;
border-radius: 15px;

font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 1vw;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;

color: #16171A;
@include btn-animate(#16171A);

}

.footerTitle{
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 2.6vh;
  font-weight: 800;
  line-height: 25px;
/* or 139% */

display: flex;
align-items: center;

color: #16171A;
padding-bottom: 10px;
}

.footerMenu{
  font-family: 'Montserrat';
font-style: normal;
font-size: 1.8vh;
font-weight: 500;
line-height: 25px;
/* or 208% */

display: flex;
align-items: center;

color: #16171A;
// padding-bottom:10px;
&:hover{
  cursor: pointer;
  transform: scale(1.01);
  transition: 0.2s all ease-in-out;
  font-weight: 700;
}
  
  
  // &:hover{
  //   cursor: pointer;
  //   overflow: hidden;
  //   position: relative;
  //   transition: all .4s ease;
  // }
}
.footerMenuImg{
  height: 3vh;
  width: 3vh;
  margin-right: 20px;
  &:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.3s all ease-in-out;
}
}
.bottomText{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 25px;
/* or 167% */

display: flex;
align-items: center;
text-align: center;
color: #16171A;
&:hover{
  cursor: pointer;
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
}
}



.contentSections{
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 80vh;
  
  // background-color: red;
  .textSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding:0px 80px;
    .bigTitle{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 75px;
      /* or 125% */
      display: flex;
      align-items: center;
      color: #16171A;
    }
    .smallSubtitle{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 45px;
      /* or 250% */
      display: flex;
      align-items: center;
      color: #16171A;
      padding-top: 60px;
      padding-right: 10vh;
    }
    .learnMoreButton{
      margin-top: 45px;
      background: #FFFFFF;
    border: 0.25px solid #16171A;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 21px 43px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    // display: flex;
    // align-items: center;
    // text-align: center;
    
    color: #16171A;
    }
  }
  .imgSection{
    
  }
}

  

.ctaSection{

  background: #16171A;
  color: white;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 45px;
/* or 250% */

display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;
// @include btn-animate(#FFFFFF);
&:hover{
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.3s all ease-in-out;
}
}

.statSection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
 
  & > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 0.3px solid #EFEFEF;
    &:nth-child(n){
      border-right:  0.5px solid #EFEFEF;
      border-bottom: 0.5px solid #EFEFEF;
    }
    
  }

  
}

.selectednav{
  font-weight: 700;
  // border-bottom: 2px solid #16171A;
  border-width: 3px;
  color: #16171A;
}

.goButton{
  width: 32px;
  height: 32px;
  background: #F3F4F5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: rotate(-180deg);
  &:hover{
      cursor: pointer;
      transform: rotate(-180deg), scale(1.2);
      transition: 0.2s all ease-in-out;
     
  }
}