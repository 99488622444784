@import "../../static/scss/mixin.scss";


.enp-home-master{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

.nav-full-box{
    height: 85.5px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 3;
}

.body-full-box{
    // height: calc(100vh - 85.5px);
    width: 100vw;
    overflow-y: scroll;
}

.body-sec1{
  margin-left: 9vw;
  width: 91vw;
  padding: 200px 0px 100px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.body-sec1-left-box{
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.body-sec1-main-title{
  display: flex;
  font-weight: 400;
  font-size: 55px;
  line-height: 65px;
  color: #16171A;
}

.body-sec1-main-title1{
  font-weight: 700;
  font-size: 55px;
  line-height: 65px;
  color: #16171A;
}

.body-sec1-sub-title{
  font-weight: 500;
  font-size: 45px;
  line-height: 65px;
  color: #16171A;
}

.body-sec1-sub-head{
  padding-top: 40px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #16171A;
}

.body-sec1-line{
  margin: 15px 0px;
  width: 23%;
  height: 4px;
  background-color: #16171A;
}

.body-sec1-each-type{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #16171A;
  padding: 20px 0px;
  cursor: pointer;
  width: fit-content;
  &:hover{
    transform: scaleX(1.05);
    transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
  }
}

.body-sec1-img{
  margin-left: 10px;
  height: 25px;
  width: 25px;
}

.body-sec1-right-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 40vw;
    // height: 400px;
    .alice-carousel__wrapper{
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      
    }
    .alice-carousel__stage-item{
      height: 420px !important;
      opacity: 0.25 !important;
      width: 300px !important;
    }
    .__target{
      opacity: 1 !important;
    }
}

.sec1-each-carousel-card{
  cursor: pointer;
  &:hover{
    // transform: scale(1.05);
    // transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 0.55);
    // border-radius: 30px;
    transform: scaleX(1.05);
    transition: 0.5s cubic-bezier(0.585, 2.5, 0.645, 1.5);
  }
}

.sec1-each-carousel-img{
  height: 420px;
}

.body-titlebox, .body-titlebox1{
    font-weight: 600;
    width: 62vw;
    display: flex;
    font-size: 65px;
    color: #16171A;
    line-height: 1.4em;
}

.body-titlebox2{
    display: none;
}

.index-module::before{
    content: '' !important;
}

.index-module::after {
    content: '' !important; 
    animation: index-module_cursor__1t4b- 1.1s infinite step-start;
}

.sec1-img-box{
    display: flex;
    justify-content: end;
}

.sec1-img{
    width: 350px;
}

.body-subtitlebox{
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 19px;
    line-height: 1em;
    width: 62vw;
    display: flex;
    color: #16171A;

}

.body-sec1-btn-box{
    width: 62vw;
    display: flex;
    // justify-content: space-between;
}

.body-sec1-btn1{
    cursor: pointer;
    width: 200px;
    height: 45px;
    border-radius: 40px;
    color: #FFFFFF;
    background-color: #16171A;
    border: 1px solid #16171A;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include btn-animate(#FFFFFF);
}

.body-sec1-btn2{
    margin-left: 25px;
    cursor: pointer;
    width: 200px;
    height: 45px;
    border-radius: 40px;
    color: #16171A;
    background-color: #FFFFFF;
    border: 1px solid #16171A;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include btn-animate(#16171A);
}

.sec1-right-name {
    margin: 20px 0px 10px 0px;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    font-weight: 700;
}

.sec1-right-desc {
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    font-weight: 500;
}

.body-mini-sec1{
    background: #16171A;
    width: 100vw;
    padding: 0px 0px 0px 9vw;
    display: flex;
    justify-content: space-between;
}

.body-mini-sec1-text{
  font-weight: 700;
  font-size: 40px;
  line-height: 65px;
  color: #FFFFFF;
  opacity: 0.75;
  width: 50vw;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.body-mini-sec1-img-box{
  height: 350px;
}

.body-mini-sec1-img{
  height: 350px;
}

.body-sec2{
    background: #16171A;
    width: 100vw;
    padding: 50px 0px;
    display: flex;
}

.body-sec2-main{
    width:72vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.body-sec2-title1{
    margin-bottom: 10px;
    font-size: 64px;
    line-height: 1em;
    font-weight: 600;
    color: #FFFFFF;
}

.body-sec2-title2{
    margin-bottom: 10px;
    font-size: 64px;
    line-height: 1em;
    font-weight: 600;
    color: #FFFFFF;
    text-decoration: underline;
    text-underline-offset: 12px;
}

.body-sec2-data{
    width: 72vw;
    display: flex;
}

.body-sec2-each-data{
    width: 24vw;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    border-right: 1px solid #FFFFFF;
    text-align: center;
}

.body-sec2-each-data1{
    width: 24vw;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-align: center;
}

.body-sec2-count-head{
    font-size: 50px;
    line-height: 1em;
    font-weight: 600;
    margin-bottom: 10px;
}

.body-sec3{
    width:72vw;
    margin: auto;
    padding: 15vmin 0px;
}

.body-sec3-title{
  font-weight: 800;
  font-size: 65px;
  line-height: 65px;
  color: #16171A;
}

.body-sec3-sub-title{
  padding-top: 10px;
  font-weight: 400;
  font-size: 35px;
  line-height: 65px;
  color: #16171A;
  
}

.body-sec3-each1, .body-sec3-each2{
    width:72vw;
    // height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 80px 0px;
    border: 0.5px solid #EFEFEF;
    border-radius: 25px;
    padding: 15px;
    &:hover{
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }

}

.body-sec3-each3{
    width:72vw;
    // height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 80px 0px;
    border: 0.5px solid #EFEFEF;
    border-radius: 25px;
    padding: 30px 15px;
    &:hover{
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }
}

.body-sec3-each-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25vw;
    height: 400px;
    background-color: #FFFFFF;
    border: 0.5px solid #EFEFEF;
    border-radius: 25px;
}

.body-sec3-each-right{
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

// .body-sec3-each-left-img{
//     width: 500px;
//     border: 0.5px solid #EFEFEF;
// }

.body-sec3-each-right-title{
    font-weight: 700;
    font-size: 45px;
    line-height: 65px;
    color: #16171A;
    // margin-bottom: 15px;
}

.body-sec3-each-right-desc{
    font-weight: 300;
    font-size: 20px;
    line-height: 42px;
    color: #16171A;
    margin-bottom: 15px;
}

.body-sec3-each-right-btn{
    cursor: pointer;
    width: 200px;
    height: 45px;
    border-radius: 40px;
    color: #FFFFFF;
    background-color: #16171A;
    border: 1px solid #16171A;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include btn-animate(#FFFFFF);
}


.body-sec3b{
  width:72vw;
  margin: auto;
  padding-bottom: 15vmin;
}

.body-sec3b-title{
  font-weight: 800;
  font-size: 65px;
  line-height: 65px;
  color: #16171A;
}

.body-sec3b-subtitle{
  font-weight: 400;
  font-size: 35px;
  line-height: 65px;
  color: #16171A;
}

.body-sec3b-all{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.zoom1{
  background: #f3f4f5;
  border: 0;
  border-radius: 30px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  transition: all .3s ease-out;
  width: 40px;
}

.check1-text{
  display: none;
}

.clickarrow-img{
  height: 20px;
  width: 20px;
}

.body-sec3b-each{
  cursor: pointer;
  padding: 30px;
  width: 30%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin: 30px 0px;
  &:hover{
    background-color: #F5F5F5;
    .check1-text{
      color: #16171A;
      display: inline-flex;
      margin-left: 9px;
      margin-top: 5px;
      font-size: 15px;
      font-weight: 700;
    }
    .clickarrow-img{
        margin-left: 0.2rem;
        margin-top: 0.3rem;
        width: 20px;
    }
    .zoom1{
        background: #fffbf0;
        height: 40px;
        text-align: center;
        width: 120px;
    }
  }
}

.body-sec3b-each-title{
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #16171A;
  margin-bottom: 20px;
}

.body-sec3b-each-img{
  height: 45px;
  width: 45px;
  margin-right: 10px;
}

.body-sec3b-each-desc{
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  color: #16171A;
  margin-bottom: 20px;
}




.body-sec4{
    width: 100vw;
    background: #f5f5f5;
    padding: 15vmin 0px;
}

.body-sec4-main{
    width:72vw;
    margin: auto;
}

.body-sec4-title{
    font-weight: 600;
    display: flex;
    font-size: 65px;
    color: #16171A;
    line-height: 1.4em;
    margin-bottom: 50px;
}

.body-sec4-slider1{
    margin-bottom: 50px;
}

.body-sec4-slider2{
    margin-bottom: 50px;
}

.body-sec4-slider-head{
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #16171A;
    margin-bottom: 10px;
}

.body-sec4-slider-desc{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #16171A;
    margin-bottom: 10px;
}


////input slider type range


.slider {
  margin: 30px 0px 70px 0px;
}

.slider-inp{
  width: 100%;
  cursor: pointer;
  accent-color: #16171A;
}

.slider-label{
  width: fit-content;
  background: #16171A !important;
  color: white !important;
  font-size: 0.65rem;
  font-weight: 800;
  padding: 5px 8px;
  border-radius: 20px;
  text-align: center;
}

.slider-label1{
  width: fit-content;
  background: #16171A !important;
  color: white !important;
  font-size: 0.65rem;
  font-weight: 800;
  padding: 5px 8px;
  border-radius: 20px;
  text-align: center;
}



/// 

  
  .body-sec4-slider-head{
    font-weight: 800;
    display: flex;
    font-size: 16px;
    color: #16171A;
    line-height: 24px;
  }

  .body-sec4-valuebox{
    display: flex;
    justify-content: space-between;
  }

  .body-sec4-slider-value{
    font-weight: 600;
    display: flex;
    font-size: 65px;
    color: #16171A;
    line-height: 1.4em;
  }

  .body-sec4-btn-box{
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .body-sec4-btn{
    cursor: pointer;
    width: 200px;
    height: 45px;
    border-radius: 40px;
    color: #16171A;
    background-color: #FFFFFF;
    border: 1px solid #16171A;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include btn-animate(#16171A);
  }

  .body-sec5{
    width:72vw;
    margin: auto;
    padding: 15vmin 0px;
  }

  .body-sec5-title{
    font-weight: 600;
    display: flex;
    font-size: 65px;
    color: #16171A;
    line-height: 1.4em;
    margin-bottom: 30px;
  }

  .body-sec5-carousel{
    width:72vw;
    margin: auto;
    height: 300px;
  }

  .sec5-each-carousel-card{
    width:72vw;
    height: 300px;
    border-left: 2px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sec5-each-carousel-left{
    margin-left: 20px;
    height: 250px;
    width: 250px;
  }

  .sec5-each-carousel-img{
    width: 250px;
  }

  .sec5-each-carousel-right{
    width: 60vw;
  }

  .sec5-each-carousel-text{
    font-size: 24px;
    line-height: 1.4em;
    font-weight: 600;
    color: #16171A;
    margin-bottom: 15px;
  }

  .sec5-each-carousel-name{
    padding: 10px;
    margin-bottom: 7px;
    background: #f5f5f5;
    color: #16171A;
    font-size: 36px;
    line-height: 1.2em;
    font-weight: 700;
  }
  
  .sec5-each-carousel-desg{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #16171A;
  }

  .body-sec6{
    background: #16171A;
    width: 100vw;
    padding: 15vmin 0px;
    display: flex;
  }

  .body-sec6-main{
    width:72vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .body-sec6-title{
    font-weight: 600;
    display: flex;
    font-size: 65px;
    color: #FFFFFF;
    line-height: 1.4em;
  }

  .body-sec6-menu-box{
    width:72vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .body-sec6-each-menu{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px 0px;
  }

  .body-sec6-menu-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2em;
    margin-bottom: 10px;
    color: #FFFFFF;
  }

  .body-sec6-menu-desc{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #FFFFFF;
  }

  .body-sec6-menu-learn{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    width: 110px;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
        transition: all ease .3s;
    }
  }

  .body-sec7{
    width:72vw;
    margin: auto;
    padding: 15vmin 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .body-sec7-title{
    width: 63vw;
    text-align: center;
    font-size: 40px;
    line-height: 65px;
    font-weight: 600;
    font-weight: 600;
    color: #16171A;
    margin-bottom: 30px;
  }

  .body-sec7-btn{
    cursor: pointer;
    width: 250px;
    height: 45px;
    border-radius: 40px;
    color: #FFFFFF;
    background-color: #16171A;
    border: 1px solid #16171A;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include btn-animate(#FFFFFF);
  }


  @media only screen and (min-width: 0px) and (max-width: 900px){

    .enp-home-master{
        overflow-x: hidden;
    }

    .body-full-box{
        overflow-x: hidden;
    }

     .body-sec1{
        width: 91%;
        margin: auto;
        padding: 100px 4.5% 0px 4.5%;
        height: auto;
      }

      .body-sec1-left-box{
        width: 91%;
        margin: auto;
      }
    
      .body-sec1-right-box{
        display: none;
      }

      .body-sec1-main-title {
        font-size: 35px;
      }

      .body-sec1-sub-title, .body-sec3b-subtitle{
        font-size: 20px;
        text-align: center;
      }

      .body-sec1-sub-head{
        font-size: 18px;
      }

      .body-sec1-line{
        width: 40%;
      }

      .body-sec1-each-type{
        font-size: 14px;
      }
    
      .body-titlebox{
        display: flex;
        font-size: 33px;
        font-weight: 600;
        color: #16171A;
        line-height: 1.4em;
      }

      .body-titlebox2{
        display: flex;
        font-size: 33px;
        font-weight: 600;
        color: #16171A;
        line-height: 1.4em;
        text-align: center;
        justify-content: center;
      }

      .body-titlebox1{
        display: none;
      }

      .body-subtitlebox{
        font-weight: 500;
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: 19px;
        line-height: 2em;
        width: 100%;
        display: flex;
        color: #16171A;
        justify-content: center;
        display: flex;
        text-align: center;
      }

      .body-sec1-btn-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .body-sec1-btn2{
        margin-left: 0px;
        margin-top: 10px;
      }

      .body-mini-sec1{
        padding: 0px 4.5%;
      }

      .body-mini-sec1-text {
        font-size: 25px;
        width: auto;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
      }

      .body-sec3-sub-title {
        padding: 10px 0px;
        font-size: 25px;
        text-align: center;
    }

      .body-mini-sec1-img-box{
        display: none;
      }

      .body-sec2-title1, .body-sec2-title2{
        font-size: 29px;
      }

      .body-sec2-data {
        width: 91%;
        display: flex;
        flex-direction: column;
        align-items: center;
        }
      .body-sec2-each-data, .body-sec2-each-data1{
        width: 91%;
        border: none;
      }

      .body-sec2-count-head {
        font-size: 32px;
        line-height: 1em;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .body-sec3-title, .body-sec3b-title{
        font-size: 35px;
        text-align: center;
      }

      .body-sec3-each1, .body-sec3-each2,  .body-sec3-each3{
        width: 91%;
        margin: 50px 4.5% 0px 4.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* justify-content: space-between; */
        align-items: center;
        text-align: center;
        padding: 30px 0px;
    }

    .body-sec3-each-left {
      width: 91%;
      height: 150px;
    }

    .body-sec3-each-left-img {
        width: auto;
        height: 150px;
    }
    
    .body-sec3-each-right {
        width: 91%;
        padding-top: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .body-sec3-each-right-title {
        font-size: 30px;
    }

    .body-sec3-each-right-desc {
        font-size: 13px;
    }

    .body-sec3b-all {
      width: 91%;
      margin: auto;
    }

    .body-sec3b-each{
      width: 100%;
      padding: 30px 15px;
      text-align: center;
    }



    .body-sec4-title {
        font-size: 33px;
        text-align: center;
    }

    .body-sec4-slider-head{
        text-align: center;
    }

    .body-sec4-slider-desc{
        font-size: 12px;
        text-align: center;
    }

    .body-sec4-valuebox{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .body-sec4-slider-value{
        font-size: 33px;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .body-sec4-btn{
        margin-top: 30px;
    }

    .body-sec4-slider-head{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .body-sec5-title, .body-sec6-title{
        font-size: 33px;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .sec5-each-carousel-card{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .sec5-each-carousel-left{
        margin-left: 20px;
        height: 150px;
        width: 150px;
    }

    .sec5-each-carousel-img{
        width: 150px;
    }

    .sec5-each-carousel-right{
        width: 100%;
    }

    .sec5-each-carousel-text{
        padding: 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .sec5-each-carousel-desg{
        text-align: center;
    }

    .sec5-each-carousel-name{
        font-size: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .body-sec6-menu-box{
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .body-sec6-each-menu{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }


    .body-sec7-title{
        width: 91%;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 15px;
    }
  }

