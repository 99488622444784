.productboxinner {
  background-color: white;
  width: 72vw;
  margin: auto;
  padding: 4px 0;
  overflow: hidden;
  position: relative;
  top: 279px;
  padding-bottom: 17px;
}

.fixedefooter {
  background: linear-gradient(275deg, #277efb 7.36%, #b580f2 140.3%);
  height: 71.045px;
  padding: 0 14vw;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;

  .labelfixedSection {
    display: flex;
    align-items: center;
    gap: 3rem;
  }

  svg {
    margin-right: 3rem;

    animation: linear infinite;
    animation-name: run;
    animation-name: run;

    -webkit-animation: linear infinite;
    -webkit-animation-name: run;
    animation-duration: 5s;
    -webkit-animation-duration: 5s;
    position: relative;
    // left:0
  }

  h1 {
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    margin-bottom: 0;
    font-weight: 700;
    margin-right: 15px;
    line-height: 35px;
    /* 175% */
    white-space: pre;
  }

  .btn-app {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 41.923px;
    background: #bacee95c;
    flex-shrink: 0;
    display: flex;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.topshow {
  position: relative;
  top: -8rem;
}

.blacktinit {
  background: #00000073;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 14px;
}

.crytoSection {
  display: flex;
  align-items: center;

  gap: 2rem;
  width: 72vw;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 4rem;

  .text {
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 75px;
    /* 300% */
    opacity: 0.25;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.povideSection {
  width: calc(100% - 26vw);
  // overflow: hidden;
  height: 41rem;

  // display: flex;
  .slick-track {
    top: 0;
    left: 0;
    height: 38rem;
    overflow: hidden;
    /* width: calc(100% - -90vw) !important; */
    // margin-left: 611vw;
  }

  .slick-dots {
    bottom: 0;
    /* width: 100%; */
    margin: 0;
    list-style: none;
    text-align: center;
    /* top: 0; */
    height: 6rem;
    left: 0;
    transform: translate(-159%, -17%);
    width: 15rem;
  }

  .slick-slide {
    margin: 0 15px;
    // background-color: white;
    border-radius: 25px;
    // margin-top: 6rem;
  }

  .slick-list {
    // margin: 0 -20px;
    padding-top: 2rem;
    height: 41rem;
    overflow: scroll;
  }

  .slick-slider {
    padding-top: 2rem;
    height: 41rem;
  }

  .slick-current {}
}

.topSectionbrand {
  height: 100vh;
  //   background: #16171a;
  padding-top: 74px;
  background-color: rgba(237, 237, 237, 0.37);
  overflow: hidden;

  // padding-top: 4rem;
  .rightsidec {
    // width: 62%;
    // img{
    //   width: 100%;
    // }
  }

  .bottomSectionbussiness {

    background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);

    padding: 0 14vw;
    height: 100%;
    padding-bottom: 34px;

    .secondcard {
      display: flex;
      align-items: flex-start;
      gap: 1.3%;
      width: 100%;
      justify-content: center;
      margin-top: 1rem;

      .box2 {
        height: 98px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          transform: scale(1.02);
        }
      }
    }

    .firstcard {
      display: flex;
      align-items: flex-start;
      gap: 1.3%;
      width: 100%;

      .box {
        height: 78px;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          transform: scale(1.02);
        }
      }
    }

    .divtext1 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px;
      /* 185.714% */
      margin-bottom: 0.5rem;
    }

    .divtext2 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 50px;
      /* 295.455% */
    }
  }

  .iinerSection {
    height: auto;
    background: white;
    /* padding-top: 35px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-left: 14vw;

    padding-right: 14vw;
    padding-top: 2rem;
    padding-bottom: 8rem;

    .topcardsec {
      display: flex;
      align-items: start;
      padding-top: 5rem;
      justify-content: space-between;
      width: 75%;
      padding-bottom: 4rem;

      h2 {
        color: #16171a;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        margin-top: 15px;
        font-weight: 500;
        line-height: 40px;
        /* 166.667% */
        margin-top: 22px;
        margin-bottom: 0;
      }

      label {
        color: #16171a;
        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 35px;
        border: 1px solid #277efb;
        background: rgba(24, 37, 66, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 242.178px;
        height: 39.937px;
        flex-shrink: 0;
        margin-bottom: 0;
        margin-top: 22px;
        cursor: pointer;

        &:hover {
          transform: scale(1.03);
        }
      }
    }

    .innserstart {
      display: flex;
      align-items: center;
      padding-top: 2rem;

      .divtect {
        color: #16171a;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        /* 181.818% */
      }

      label {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 283px;
        height: 50.817px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
        // background-color: linear-gradient(318deg, #277EFB 0%, #B580F2 100%) !important;
        opacity: 0.8999999761581421;
        margin-right: 10px;
        margin-bottom: 0;

        &:hover {
          transform: scale(1.03);
        }
      }
    }

    .cardin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31.33%;
      border-radius: 20px;
      border: 1px solid #ddd;
      height: 305px;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
      }
    }

    h5 {
      color: #16171a;
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 300;
      margin-top: 10px;

      font-family: "Montserrat";
      font-style: normal;
      line-height: 35px;
      /* or 159% */
      margin-top: 1rem;
      margin-bottom: 0;
    }

    img {
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  .cardsSection {
    display: flex;
    align-items: center;

    .black {
      background: #000000e0;
      background-blend-mode: multiply;
      width: 100%;
      position: absolute;
      height: 100%;
    }

    .item {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      position: relative;
      height: 287px;
    }

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 0;
      color: #ffffff;
      z-index: 1;
    }
  }

  .innerleftside {
    width: 680px;
    max-width: 680px;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    overflow: hidden;
    line-height: 55px;
    /* or 118% */

    margin-bottom: 2.3rem;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0.3rem;
    color: #ffffff;

    position: relative;
    white-space: nowrap;
    position: relative;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 55px;
      /* or 118% */
      margin-left: 13px;
      margin-right: 13px;

      text-align: center;

      .text-transition {
        div {
          background: linear-gradient(90deg, #277efb 0%, #b580f2 121.81%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          zujtext-fill-color: transparent;
        }
      }
    }
  }

  .labelSection {
    display: flex;
    align-items: center;
    margin-top: 3.5rem;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0px 14vw;
    background: #16171a;
    height: calc(100% - 337px);
    // padding-bottom: 5rem;
  }

  .start {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 196px;
    height: 54px;
    color: #ffffff;
    background: #16171a;

    border-radius: 35px;
    border: 1px solid #ffffff;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    img {
      display: none;
    }
  }

  .cardsTopSectionInfo {
    background-color: white;
    padding: 0 14vw;
    height: 262px;
    padding-top: 1px;
    // overflow: hidden;
    padding-top: 35px;

    .marketsverseapp {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #16171a;
      margin-top: 26px;
    }
  }

  .login {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 260px;
    height: 54px;
    color: #ffffff;

    background: transparent;
    border: 2px solid #277efb;
    opacity: 0.9;
    border-radius: 35px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    img {
      display: none;
    }
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    width: 1016px;
    display: flex;
    // align-items: center;
    // text-align: center;
    margin-top: 2rem;
    color: #ffffff;
    margin-bottom: 0;
    width: 465.197px;
  }
}

.blackoverlab {
  background: #16171a;
  background-blend-mode: multiply;
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
}

.section_1_inner {
  display: flex;
  padding-left: 14vw;
  height: 39rem;
  overflow: hidden;
  padding-right: 14vw;

  .item {
    margin-right: 4rem;
    padding-top: 11rem;
  }

  p {
    width: 22rem;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 30px;
    /* or 175% */
    margin-bottom: 3rem !important;

    display: flex;
    align-items: center;

    color: #16171a;
  }

  h1 {
    width: 323px;
    height: 150px;
    margin-bottom: 2rem;
    top: 296px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 60px;
    /* or 133% */

    display: flex;
    align-items: center;

    color: #16171a;
  }
}

.comingsoonlogo {
  width: 40rem;
  height: 100%;
}

@media only screen and (min-width: 100px) and (max-width: 900px) {
  .topSectionbrand .innerleftside {
    width: 100%;
    max-width: 100%;
  }

  .comingsoonlogo {
    width: 20rem;
    height: 100%;
  }

  .fixedefooter {
    background: linear-gradient(275deg, #277efb 7.36%, #b580f2 140.3%);
    height: 71.045px;
    padding: 0 20px;
  }

  .fixedefooter .labelfixedSection {
    gap: 1rem;
    /* display: none; */
    width: 100%;
    overflow: scroll;
  }

  .fixedefooter svg {
    margin-right: 1rem;
  }

  .fixedefooter .btn-app {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fixedefooter h1 {
    margin-bottom: 0;
    font-weight: 700;
    margin-right: 15px;
    line-height: 24px;
    white-space: pre;
  }

  .title {
    display: flex !important;
    font-size: 2rem !important;
    text-align: center !important;
    justify-content: center !important;
    width: 100%;
  }

  .title1 {
    background: linear-gradient(90deg, #277efb 0%, #b580f2 121.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .topSectionbrand .iinerSection .innserstart {
    display: flex;
    align-items: center;
    padding-top: 3rem;
    flex-direction: column;
    justify-content: center;
  }

  .iinerSection {
    .img {
      width: 80%;
    }
  }

  .rightsidec {

    // display: none;
    img {
      width: 100%;
    }
  }

  .topSectionbrand .bottomSectionbussiness .firstcard .box {
    width: 9rem;
    padding: 1.3rem;
    max-width: 87px;
    width: 97px;
  }

  .topSectionbrand .bottomSectionbussiness .firstcard .box svg {

  }

  .topSectionbrand .bottomSectionbussiness .secondcard {
    overflow: scroll;
    width: 100%;


  }

  .topSectionbrand .bottomSectionbussiness .secondcard .box2 {
    width: 9rem;
    padding: 1.3rem;
    max-width: 87px;
    width: 97px;

  }

  .topSectionbrand .bottomSectionbussiness .secondcard .box2 svg {


  }

  .topSectionbrand .bottomSectionbussiness .secondcard .box2 {
    height: 76px;
  }

  .topSectionbrand .bottomSectionbussiness .firstcard {
    overflow: scroll;
  }

  .topSectionbrand .bottomSectionbussiness {
    height: auto;
    background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    flex-direction: column;
    padding-top: 0;
  }

  .topSectionbrand .bottomSectionbussiness .divtext1 {
    font-size: 18px;

    line-height: 43px;
  }

  .topSectionbrand .bottomSectionbussiness .divtext2 {
    font-size: 13px;

    font-weight: 500;
    line-height: 25px;
  }

  .topSectionbrand .iinerSection {
    height: auto;
    display: block;
    padding: 3rem 20px;
    text-align: center;
  }

  .topSectionbrand .iinerSection h5 {
    width: auto;
    font-size: 17px;
    line-height: 28px;
    text-align: center !important;
    margin-bottom: 2rem;
  }

  .topSectionbrand .iinerSection .innserstart .divtect {
    color: #16171a;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
  }

  .topSectionbrand .iinerSection .cardin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ddd;
    height: 254px;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 1.5rem;
  }

  .topSectionbrand .iinerSection .topcardsec {
    display: block;
    align-items: start;
    padding-top: 3rem;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0;
  }

  .topSectionbrand .iinerSection .innserstart label {
    margin-right: 0px;
    margin-bottom: 1.5rem;
  }

  .topSectionbrand {
    .cardsTopSectionInfo {
      background-color: white;
      padding: 0 20px;
      height: 173px;
      padding-top: 16px;
      // overflow: hidden;
    }
  }

  .blackoverlab {
    background: #000000b3;
    background-blend-mode: multiply;
  }

  .text-transition {
    height: auto !important;
  }

  .eachLeftCards2 {
    background: white;
  }

  .eachdescbox {
    border: 0px solid;
  }

  .showmible {
    display: block !important;
  }

  .povideSection {
    display: none !important;
  }

  .flip-card {
    background-color: transparent;
    width: 100% !important;
    height: auto !important;
    margin-bottom: 3rem;
    perspective: 1000px;
    margin-top: 3rem;
  }

  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner {
    // transform: rotateY(180deg)  scale(1.1);
    border: 0px solid #16171a !important;
    //   -webkit-transform: rotateY(0) scale(1) !important;
    //   -ms-transform: rotateY(0) scale(1)!important;
    //   transform: rotateY(0) scale(1)!important;
    // transform: scale(1.1);
    // overflow: hidden;
    transition: all 0.3s ease-out;
    // zoom: 1.1;
    // height: 43rem;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 25rem !important;
  }

  // .flip-card-front, .flip-card-back {
  //     position: relative !important;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 25px;
  // }
  .flip-card {
    margin-top: 0rem !important;
    margin-bottom: 1rem !important;
    /* margin-top: 3rem; */
  }

  .flip-card .provideDesc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 1.2rem;
    color: #16171a;
    margin-top: 1rem;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border-radius: 25px;
  }

  .povideSection .slick-slide {
    margin: 0 0px;
    // background-color: white;
    border-radius: 25px;
    height: auto;
  }

  .blackoverlab {
    top: 0;
  }

  .povideSection {
    width: 100%;
    height: auto;
  }

  // .povideSection .slick-current {
  //     border: 0px solid #16171A;
  //     // transform: scale(1.1);
  //     border-radius: 25px;
  // }
  .povideSection .slick-dots {
    bottom: 0;
    /* width: 100%; */
    margin: 0;
    list-style: none;
    text-align: center;
    /* top: 0; */
    height: 6rem;
    left: 0;
    transform: translate(4%, 0);
    width: 15rem;
  }

  .section_1_inner {
    display: block;
    padding-left: 0;
    height: auto;
    overflow: hidden;
    padding-right: 0;

    .item {
      margin-right: 0;
      padding-top: 0;
    }

    h1 {
      width: auto;
      height: auto;
      margin-bottom: 2rem;
      top: 296px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 31px;
      line-height: 44px;
      display: flex;
      align-items: center;
      color: #16171a;
    }

    .section_1_inner p {
      width: 100%;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 1px;
      line-height: 31px;
      margin-bottom: 3rem !important;
      display: flex;
      align-items: center;
      color: #16171a;
    }
  }

  .topSectionbrand {
    height: auto;
    padding-bottom: 2rem;

    .iinerSection {
      height: auto;
      display: block;
      padding: 3rem 20px;

      img {
        // margin-bottom: 2rem;
      }

      h5 {
        width: auto;
        font-size: 17px;
        line-height: 28px;
        text-align: left;
        margin-bottom: 2rem;
      }
    }

    .inner {
      align-items: start;
      height: auto !important;
      padding: 3rem 20px;
      padding-top: 3rem;
      display: block;
    }

    .labelSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3.5rem;
      width: 100%;
    }

    .login {
      width: 100%;
      font-size: 19px;
      margin-right: 0;
      margin-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      justify-content: space-between;
      padding: 0 2rem;

      &:hover {
        transform: scale(1);
      }

      img {
        display: block;
      }
    }

    .start {
      font-size: 19px;
      width: 100%;
      margin-bottom: 1.5rem;
      justify-content: space-between;
      padding: 0 2rem;

      &:hover {
        transform: scale(1);
      }

      img {
        display: block;
      }
    }

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      justify-content: center;
      width: auto;
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      overflow: hidden;
      line-height: 55px;
      margin-bottom: 2.3rem;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      height: 60px;
      position: relative;
      /* white-space: nowrap; */
      position: relative;
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;

      span {
        font-size: 46px;
        line-height: 55px;
        margin: 0;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .cardsSection {
      display: block;

      .item {
        width: 100%;
      }
    }
  }
}

.flip-card {
  background-color: transparent;
  width: 33%;
  height: 30rem;
  margin-bottom: 3rem;
  perspective: 1000px;
  margin-top: 3rem;

  .web {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    /* or 281% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    width: 100%;
    height: 51px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    border: 1px solid #ffffff;
    justify-content: center;
    border-radius: 35px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .tem {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    /* or 281% */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #277efb;
    width: 100%;
    height: 51px;

    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 35px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .label-section-bck {
    display: block;
    align-items: center;
    width: 100%;
    // margin-top: 1.5rem;
    z-index: 9999;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 65px;
    /* or 217% */

    display: flex;
    margin-bottom: 0;
    line-height: 47px;
    align-items: center;

    color: #4b9ddc;
  }

  .providesubHead {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    /* or 225% */

    display: flex;
    align-items: center;
    margin-bottom: 0rem;
    text-align: left;
    color: #16171a;
  }

  .provideDesc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    /* or 176% */

    display: flex;
    align-items: center;

    text-align: left;
    margin-bottom: 1.2rem;
    color: #16171a;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  // transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  border-radius: 25px;
}

.flip-card:focus {
  outline: 0;
}

.flip-card-back {
  &:hover {
    display: none;
  }
}

.flipchoverfalse {
  border: 0px solid #16171a;
  -webkit-transform: rotateY(0deg) scale(1) !important;
  -ms-transform: rotateY(0deg) scale(1) !important;
  transform: rotateY(0deg) scale(1) !important;
  // transform: scale(1.1);
  // overflow: hidden;
  transition: all 0.3s ease-out;

  .flip-card-front {
    display: flex;
  }

  .flip-card-back {
    position: absolute;
    width: 99%;
    height: 99%;
  }
}

.flipchover {
  border: 0.5px solid #16171a;
  -webkit-transform: rotateY(0deg) scale(1) !important;
  -ms-transform: rotateY(0deg) scale(1) !important;
  transform: rotateY(0deg) scale(1) !important;
  // transform: scale(1.1);
  // overflow: hidden;
  transition: all 0.3s ease-out;

  .flip-card-front {
    display: none;
  }

  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.flip-card-back {
  background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
  color: white;
  // transform: rotateY(0deg) !important;
  // -ms-transform: rotateY(0deg) !important;
  // -webkit-transform: rotateY(0deg) !important
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  // transform: rotateY(180deg)  scale(1.1);
  border: 0.5px solid #16171a;
  -webkit-transform: rotateY(180deg) scale(1.1);
  -ms-transform: rotateY(180deg) scale(1.1);
  transform: rotateY(180deg) scale(1.1);
  // transform: scale(1.1);
  // overflow: hidden;
  transition: all 0.3s ease-out;
  // zoom: 1.1;
  // height: 43rem;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  z-index: 99;
}

.flip-card-front {
  // background: linear-gradient(to left, #4364f7, #6fb1fc);
  background-color: white;
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5rem;
}

.flip-card-back {
  background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
  color: white;
  transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding: 1.5rem;
}

.showmible {
  display: none;
}

.scene {
  display: inline-block;
  width: 100% !important;
  height: 36rem !important;
  /* border: 1px solid #CCC; */
  margin-bottom: 1rem;
  border-radius: 25px;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;

  /*   border: 1px solid #CCC; */
  &:hover {
    transform: scale(1.1);
  }

  .web {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    /* or 281% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    width: 100%;
    height: 51px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    border: 1px solid #ffffff;
    justify-content: center;
    border-radius: 35px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .tem {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    /* or 281% */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #277efb;
    width: 100%;
    height: 51px;

    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 35px;

    &:hover {
      transform: scale(1.1);
    }
  }

  .label-section-bck {
    display: block;
    align-items: center;
    width: 100%;
    // margin-top: 1.5rem;
    z-index: 9999;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 65px;
    /* or 217% */

    display: flex;
    margin-bottom: 0;
    line-height: 47px;
    align-items: center;

    color: #4b9ddc;
  }

  .providesubHead {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    /* or 225% */

    display: flex;
    align-items: center;
    margin-bottom: 0rem;
    text-align: left;
    color: #16171a;
  }

  .provideDesc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    /* or 176% */

    display: flex;
    align-items: center;

    text-align: left;
    margin-bottom: 1.2rem;
    color: #16171a;
  }

  .card {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 1s;
    border-radius: 25px;
  }

  .card.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 260px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    backface-visibility: hidden;
    border-radius: 25px;
  }

  .card__face--front {
    background-color: white;
    color: black;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    padding: 1.5rem;
  }

  .card__face--back {
    background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
    transform: rotateY(180deg);
    border-radius: 25px;
    background-color: white;
    color: white;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    padding: 1.5rem;

    img {
      position: absolute;
      right: 23px;
      top: 22px;
      cursor: pointer;
    }
  }
}

// .activeclass{
//     transform: scale(1.1);
// }




@media only screen and (min-width: 100px) and (max-width: 900px) {
  .scene {
    display: inline-block;
    width: 100% !important;
    height: 36rem !important;
    /* border: 1px solid #CCC; */
    margin-bottom: 1rem;
    border-radius: 25px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    /*   border: 1px solid #CCC; */
    &:hover {
      transform: scale(1);
    }
  }
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Dark background with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 30rem;
  // height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    width: 4rem !important;
    height: 3rem !important;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    margin-left: 5px;
    margin-right: 5px;
    // margin-right: 1rem;
  }
}

.textcolor {
  background: linear-gradient(90deg, #277efb 0%, #b580f2 121.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 2rem;
}

.submit {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 196px;
  height: 54px;
  color: #ffffff;

  background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
  opacity: 0.9;
  border-radius: 35px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  img {
    display: none;
  }
}

// Mobile

.mobsection {
  height: 100%;
  width: 100%;
  background: #16171a;
  padding-top: 78px;
  // padding-top: 4rem;

  .iinerSection {
    height: auto;
    background: #16171a;
    /* padding-top: 35px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-left: 14vw;

    padding-right: 14vw;
    padding-top: 2rem;
    padding-bottom: 8rem;

    .topcardsec {
      display: flex;
      align-items: start;
      padding-top: 3rem;
      justify-content: space-between;
      width: 75%;
      padding-bottom: 2rem;

      h2 {
        color: #16171a;
        text-align: center;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        margin-top: 15px;
        font-weight: 500;
        line-height: 40px;
        /* 166.667% */
        margin-top: 22px;
        margin-bottom: 0;
      }

      label {
        color: #16171a;
        text-align: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 35px;
        border: 1px solid #277efb;
        background: rgba(24, 37, 66, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 242.178px;
        height: 39.937px;
        flex-shrink: 0;
        margin-bottom: 0;
        margin-top: 22px;
        cursor: pointer;

        &:hover {
          transform: scale(1.03);
        }
      }
    }

    .innserstart {
      display: flex;
      align-items: center;
      padding-top: 2rem;

      .divtect {
        color: #16171a;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        /* 181.818% */
      }

      label {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 283px;
        height: 50.817px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        background: linear-gradient(275.12deg, #277efb 7.36%, #b580f2 140.3%);
        // background-color: linear-gradient(318deg, #277EFB 0%, #B580F2 100%) !important;
        opacity: 0.8999999761581421;
        margin-right: 10px;
        margin-bottom: 0;

        &:hover {
          transform: scale(1.03);
        }
      }
    }

    .cardin {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 31.33%;
      border-radius: 20px;
      border: 1px solid #ddd;
      height: 305px;
      flex-direction: column;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
      }
    }

    h5 {
      color: #16171a;
      text-align: center;
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 300;
      margin-top: 10px;

      font-family: "Montserrat";
      font-style: normal;
      line-height: 35px;
      /* or 159% */
      margin-top: 1rem;
      margin-bottom: 0;
    }

    img {
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  .cardsSection {
    display: flex;
    align-items: center;

    .black {
      background: #000000e0;
      background-blend-mode: multiply;
      width: 100%;
      position: absolute;
      height: 100%;
    }

    .item {
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      position: relative;
      height: 287px;
    }

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 0;
      color: #ffffff;
      z-index: 1;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 55px;
    overflow: hidden;
    line-height: 65px;
    /* or 118% */

    margin-bottom: 2.3rem;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0.3rem;
    color: #ffffff;

    position: relative;
    white-space: nowrap;
    position: relative;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 55px;
      line-height: 65px;
      /* or 118% */
      margin-left: 13px;
      margin-right: 13px;

      text-align: center;

      .text-transition {
        div {
          background: linear-gradient(90deg, #277efb 0%, #b580f2 121.81%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }

  .labelSection {
    display: flex;
    align-items: center;
    margin-top: 3.5rem;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #16171a;
    height: calc(100%);
  }

  .start {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 196px;
    height: 54px;
    color: #ffffff;
    background: #16171a;

    border-radius: 35px;
    border: 1px solid #ffffff;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    img {
      display: none;
    }
  }

  .cardsTopSectionInfo {
    background-color: white;
    padding: 0 14vw;
    height: 262px;
    padding-top: 1px;
    // overflow: hidden;
    padding-top: 35px;

    .marketsverseapp {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #16171a;
      margin-top: 26px;
    }
  }

  .login {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 196px;
    height: 54px;
    color: #ffffff;
    background: transparent;
    border: 2px solid #277EFB;
    opacity: 0.9;
    border-radius: 35px;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    img {
      display: none;
    }
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 41px;
    width: 1016px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
    color: #ffffff;
    margin-bottom: 0;
    width: 696px;
  }
}

@keyframes run {
  0% {
    left: 0;
  }

  50% {
    left: 0.3rem;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }

  50% {
    left: 0.6rem;
  }

  100% {
    left: 0;
  }
}




@media only screen and (max-width: 1650px) {

  .topSectionbrand .inner {

    height: 32rem;
  }

  .topSectionbrand {
    height: auto;
  }
}