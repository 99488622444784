.player-wrapper {
    position: relative;
    width: 100% !important;
    height: 100% !important;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100% !important;
    height: 100% !important;
    // width: 100%;
    // height: 100%;
  }
  