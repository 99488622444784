
.optionsMaster{
    height: 100%;
    width: 100%;
    color: #16171A;
    padding: 25px 25px 0 0px;
    .headersection{
        height: 150px;
        width: 90%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        // align-items: center;
        background-color: white;
        .maintitle{
            font-weight: 800;
            font-size: 25px;
            padding-bottom: 20px;
        }
        .maindesc{
            font-weight: 300;
            font-size: 12px;
            line-height: 27px;
        }
    }
    .cardsSection{
        height: calc(100vh - 360px);
        width: 100%;
        overflow-y: scroll;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .eachcard{
          display: flex;
          flex-direction: column;
          justify-content: center;
            cursor: pointer;
            height: 30vh;
            width: 90%;
            margin: 20px 0;
            padding: 30px;
            border-top: 0.5px solid #EFEFEF;
            border-left: 15px solid  #16171A;
            border-right: 0.5px solid #EFEFEF;
            border-bottom: 0.5px solid #EFEFEF;
            border-radius: 15px;
            opacity: 0.5;
            .queryTitle{
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
            }
            .queryDesc{
              font-weight: 300;
              font-size: 12px;
              line-height: 27px;
            }
          }
          .eachcard:hover{
            // background-color: #f5f5f5;
            transform: scale(1.05);
            transition: .6s ease;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            opacity: 1;
          }
          .selectactive{
            // background-color: #f5f5f5;
            transform: scale(1.05);
            transition: .6s ease;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            opacity: 1;
          }
    }
}