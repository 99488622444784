.landing-main-box{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-btn-box{
    padding-left: 5vw;
    width: 35vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: start;
    text-align: left;
    font-weight: 600;
    font-size: 35px;
    line-height: 75px;
    color: #16171A;
    transition: all ease 2s;
    padding-top: 40vh;
    .hover-text{
    color: #16171A;
    }
    &:hover{
        .hover-text{
            // animation: fadeIn .2s linear;
            // transition: all ease 2s;
            // display: flex;
        }
        // .btns-each{
        //     animation: fadeIn .2s linear;
        //     transition: all ease 2s;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;
        // }
    }
    label{
        font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
opacity: 0.9;
border-radius: 35px;
justify-content: center;
width:162.64px;
height:39px;
margin-top: 1rem;
align-items: center;
text-align: center;
display: none;
margin: auto;
margin-top: 1rem;
color: #FFFFFF;
    }

}

.right-btn-box{
    padding-right: 5vw;
    width: 35vw;
    height: 100vh;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: end;
    text-align: right;
    font-weight: 600;
    font-size: 35px;
    line-height: 75px;
    color: #16171A;
    transition: all ease 2s;
    padding-top: 40vh;
    .hover-text1{
        color: #16171A;
        }
    &:hover{
        .hover-text1{
            // animation: fadeIn .2s linear;
            // transition: all ease 2s;
            // display: flex;
        }
        // .btns-each1{
        //     animation: fadeIn .2s linear;
        //     transition: all ease 2s;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;
        // }
  
    }
    label{
        font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
opacity: 0.9;
border-radius: 35px;
justify-content: center;
width:162.64px;
height:39px;
margin-top: 1rem;
align-items: center;
text-align: center;
display: none;
color: #FFFFFF;
margin: auto;
margin-top: 1rem;
    }
}

.main-img-box{
    width: 30vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #FFFFFF 50%, #F5F5F5 50%);
}

.main-img-icon{
    height: 350px;
    width: 350px;
}

.btns-each{
    transition: all ease .6s;
    height: 60vh;
    padding-bottom: 10vh;
    width: 100%;
    animation: fadeIn .2s linear;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: start;
    text-align: left;
    justify-content: space-between;
}


.btns-each1{
    transition: all ease .6s;
    height: 60vh;
    padding-bottom: 10vh;
    justify-content: space-between;
    width: 100%;
    animation: fadeIn .2s linear;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: end;
    text-align: right;
}

.btn-img-box{
    cursor: pointer;
    height: 70px;
    width: 70px;
    &:hover{
        transform: scale(1.1);
        transition: all ease .3s;
    }
}

.btn-img-icon{
    height: 70px;
    width: 70px;
}


/// spin

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(190deg);
      -o-transform: rotate(190deg);
      transform: rotate(190deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(190deg);
      -moz-transform: rotate(190deg);
      -webkit-transform: rotate(190deg);
      -o-transform: rotate(190deg);
      transform: rotate(190deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear;
    -moz-animation: rotating 1s linear;
    -ms-animation: rotating 1s linear;
    -o-animation: rotating 1s linear;
    animation: rotating 1s linear;
  }


  // fade in

  @keyframes fadeIn {
    // 0% { opacity: 0; }
    // 50% { opacity: 0.5; }
    // 100% { opacity: 1; }
    0%{opacity: 0;}
    // 25%{opacity: .25;}
    // 50%{opacity: .50;}
    // 75%{opacity: .75;}
    100%{opacity: 1;}
  }

  @media only screen and (min-width: 0px) and (max-width: 900px){
.type-name{
    text-align: center;
}
    .left-btn-box{
        padding: 0;
        padding-left: 5%;
        padding-right: 5%;
    }
    .right-btn-box{
        padding: 0;
        padding-left: 5%;
        padding-right: 5%;
label{
    display: flex;
    
}
        }
        .left-btn-box{
            label{
                display: flex;
            }

        }
    .landing-main-box{
        display: block;
        flex-direction: column;
        overflow: scroll;
    }

    .left-btn-box, .right-btn-box{
        height:25rem;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        // overflow: scroll;
    }
    .btns-each
    {

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-align: center;
}
.btns-each1{
    text-align: center;
    padding: 0;
    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;
}
    .main-img-box{
        height: 15vh;
        width: 100vw;
        background: linear-gradient(180deg, #FFFFFF 50%, #F5F5F5 50%);
    }
    .btn-img-box{
        display: none;
    }

    .main-img-icon{
        height: 100%;
    }

    .btns-each, .btns-each1{
        height: 42.5vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    // .type-name{
    //     width: 65vw;
    // }

    .btn-img-box{
        height: auto;
        width: auto;
        margin-left: 25px;
    }

    .btn-img-icon{
        height: 35px;
        width: 35px;
    }
  }

  @media only screen and (min-width: 901px) and (max-width: 1200px){

    .main-img-icon{
        height: 250px;
        width: 250px;
    }

    .right-btn-box, .left-btn-box{
        font-size: 30px;
    }
  }

  .hover-text{
    transition: all ease .6s;
    display: flex;
    font-weight: 300;
font-size: 18px;
line-height: 35px;
margin-top: 10px;
  }

  .hover-text1{
    transition: all ease .6s;
    display: flex;
    font-weight: 300;
font-size: 18px;
line-height: 35px;
margin-top: 10px;
  }



  // base
.flip {
    position: relative;
    overflow: hidden;
  
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 45px;
      /* or 129% */
  
      margin-bottom: 0px;
  
      color: #ffffff;
    }
    P {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 19px;
      /* or 225% */
      margin-bottom: 0;
      margin: 0;
      color: #ffffff;
    }
    h2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 45px;
  
      display: flex;
      align-items: center;
  
      color: #212224;
    }
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 30px;
      /* or 176% */
      margin-bottom: 1rem;
      margin-top: 2rem;
      display: flex;
      align-items: center;
  
      color: #5f6163;
    }
    label {
      font-style: normal;
      font-weight: 600;
      font-weight: 600;
      font-size: 19px;
      /* or 221% */
      background: #4caf50;
      border-radius: 15px;
      height: 54px;
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      text-align: center;
      height: 54px;
    width: 90%;
   
    left: 0;
    border-radius: 35px;
      color: #ffffff;
      &:hover {
        transform: scale(1.1);
      }
    }
    h6 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 19px;
      /* or 225% */
  
      color: #5f6163;
    }
    > .front,
    > .back {
      display: block;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 0.5s;
      transition-property: transform, opacity;
    }
    > .front {
      //transform: rotateY(0deg);
    }
    > .back {
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: none;
      // transform: rotateY(-180deg);
    }
    &:hover {
      > .front {
        cursor: pointer;
        display: none;
      }
      > .back {
        opacity: 1;
        display: flex;
        cursor: pointer;
      }
    }
    &.flip-vertical {
      > .back {
        display: none;
      }
      &:hover {
        > .front {
          display: none;
        }
        > .back {
          display: flex;
        }
      }
    }
  }
  
  // custom
  .flip {
    position: relative;
    display: flex;
    // margin-right: 2px;
  
    width: 33.33%;
    height: 100vh;
    padding-top: 72px;
    .front {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      justify-content: flex-end;
      background-size: cover !important;
      background-position: center !important;
      height: 100%;
      background-color: #4d4c4cc4;
      background-blend-mode: multiply;
      width: 100%;
      padding-bottom: 2rem;
      padding-left: 5%;
      img {
        margin-bottom: 1rem;
      }
    }
    > .back {
      display: block;
      //color: white;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      justify-content: center;
  
      background-size: cover !important;
      background-position: center !important;
      height: 100%;
      padding-bottom: 5rem;
      padding-left: 5%;
      padding-right: 5%;
      background: #fff;
      width: 100%;
      .backImg {
        margin-bottom: 1rem;
      }
      .about-secton {
        // display: flex;
        align-items: center;
        position: absolute;
  
        bottom: 2rem;
        width: 100%;
        h3 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
          color: #212224;
        }
        .box {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          background: rgba(40, 55, 144, 0.95);
          border-radius: 5px;
          margin-left: 10px;
          img {
          }
        }
      }
      p {
        font-size: 0.9125rem;
        line-height: 160%;
        color: #999;
      }
    }
  }
  
  .innerbannerLanding {
    height: 59px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 3.5%;
    justify-content: space-between;
    padding-right: 3.4%;
    background: linear-gradient(180deg, #283790 0%, #000000 147.45%);
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: right;
      text-decoration-line: underline;
  
      color: #ffffff;
      margin-bottom: 0px;
    }
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
  
      color: #ffffff;
    }
  }
  
  .flipCard {
    width: 50% !important;
    .front {
      padding-left: 6% !important;
    }
    .back {
      padding-left: 6% !important;
    }
  }
  
  .cardSection {
    display: flex;
    background: black;
    align-items: center;
  }
  
  @media only screen and (max-width: 991.9px) {
    .innerbannerLanding {
      h5 {
        display: none;
      }
    }
    .landingpage {
      height: 100vh;
      position: relative;
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
  
        color: #ffffff;
      }
      h5 {
        display: none;
      }
      h1 {
        margin-bottom: 150px;
      }
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 41px;
        line-height: 55px;
        display: flex;
        align-items: center;
        width: 100%;
        color: #ffffff;
      }
      .props-object-landling {
        display: block;
        align-items: center;
        width: 93.2%;
        position: absolute;
        bottom: 2rem;
  
        justify-content: space-between;
        label {
          width: 100%;
          font-weight: 600;
          font-size: 20px;
          margin-top: 1.5rem;
        }
      }
    }
  
    .cardSection {
      display: block;
      background: black;
      align-items: center;
    }
    .flip {
      position: relative;
      display: flex;
      width: 100%;
      .front {
        padding-left: 4%;
      }
      .back {
        padding-left: 4%;
      }
    }
    .flipCard {
      width: 100% !important;
      .front {
        padding-left: 4% !important;
      }
      .back {
        padding-left: 4% !important;
      }
    }
  }
  

  .navlogo{
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 71px;
    z-index: 99999;
    border-bottom: 5px solid #277EFB;
    background: #fff;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(90deg, #277EFB, #B580F2) 1 stretch;
  
  }