.companymaster{
    margin-top: 109px;
}

.company-nav{
    background-color: white;
    z-index: 1;
    height: 50px;
    width: 100vw;
    padding-left: 9vw;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #e5e5e5;
}

.each-company-nav{
    width: 10%;
    font-weight: 400;
    font-size: 15px;
    line-height: 13px;
    color: #16171A;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover{
        font-weight: 700;
        color: #16171A;
    }
}

.each-company-nav-active{
    width: 10%;
    font-weight: 700;
    font-size: 17px;
    line-height: 13px;
    color: #16171A;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.company-main, .contact-main{
    overflow-x: hidden;
    // height: calc(100vh - 159px);
    // width: 100vw;
    display: flex;
    // margin: auto;
}

.founder-main{
    overflow-x: hidden;
    // height: calc(100vh - 159px);
    // width: 100vw;
    display: flex;
    justify-content: space-between;
}

.company-leftsidebox{
    // height: calc(100vh - 159px);
    // width: 60vw;
    padding: 0px 120px 0px 9vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.company-rightsidebox{
    height: calc(100vh - 159px);
    width: calc( 30vw - 0.5px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.left-1{
    display: flex;
    flex-direction: column;
    // justify-content: end;
    height: 25%;
}

.left-2{
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    // height: 75%;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    color: #16171A;
}

.comp-mainhead{
    font-weight: 800;
    font-size: 48px;
    color: #16171A;
}

.comp-subhead{
    font-weight: 600;
    font-size: 22px;
    color: #16171A;
}

.gx-image{
    height: 28px;
    width: 28px;
    margin: 0px 5px;
}

.each-compmenu{
    height: 23%;
    width: 100%;
    display: flex;
    border-radius: 15px;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}


.each-title{
    font-weight: 800;
    font-size: 25px;
    line-height: 24px;
    color: #FFFFFF;
}

.each-desc{
    padding-top: 10px;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
    color: #FFFFFF;
}

.each-icon{
    // width: 15%;
    display: flex;
    justify-content: end;
}

.each-data{
    // width: 50%;
}

.each-arrow{
    width: 10%;
    cursor: pointer;
}

.founder-img-box{
    height: 100%;
}

.founder-each-img{
    width: 300px;
    height: 100%;
    background-repeat:no-repeat;
    background-size:contain;
}

@media screen and (min-height: 1027px) and (max-height: 3000px){
    .founder-each-img{
        background-size:cover;
    }
}

.founder-text-box{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 60vw;
    padding-left: 9vw;
}

.founder-title{
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // font-size: 48px;
    font-size: 3.12vmax;
    color: #16171A;
}

.founder-subtitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    // font-size: 22px;
    font-size: 1.45vmax;
    color: #16171A;
}

.founder-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0px;
    font-weight: 300;
    // font-size: 14px;
    font-size: 0.9vmax;
    line-height: 30px;
    color: #16171A;
}

.founder-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
    // font-size: 25px;
    font-size: 1.6vmax;
    color: #16171A;
}

.founder-data{
    display: flex;
    align-items: center;
    margin: 10px 0px;
    font-weight: 500;
    // font-size: 14px;
    font-size: 0.9vmax;
    line-height: 30px;
    color: #5F6163;
}

.founder-full-img{
    height: 100%;
}

.mob-companymaster{
    display: none;
}

@media only screen and (min-width: 0px) and (max-width: 900px){
    // .companymaster{
    //     overflow: auto;
    // }

    // .company-nav{
    //     background-color: white;
    //     z-index: 1;
    //     height: 50px;
    //     width: 100vw;
    //     padding-left: 40px;
    //     display: -webkit-inline-box;
    //     align-items: center;
    //     border-bottom: 0.5px solid #e5e5e5;
    //     overflow-x: scroll;
    // }

    // .each-company-nav, .each-company-nav-active{
    //     width: auto;
    //     margin-right: 40px;
    // }

    .companymaster{
        display: none;
    }

    .mob-companymaster{
        margin-top: 120px;
        height: calc(100vh - 120px);
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mob-each-company-nav{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 76vw;
        margin: 30px 0px;
        // padding: 35px 0px;
        border: 0.5px solid #e5e5e5;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 20px;
        color: #16171A;
        font-weight: 500;
    }

    .company-main{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 20px;
        height: auto;
    }

    .company-leftsidebox{
        height: auto;
        width: 100%;
        padding: 0px;
    }

    .company-rightsidebox{
        width: 100%;
        padding-bottom: 30px;
    }

    .each-title{
        font-size: 15px;
    }

    .founder-main{
        overflow-x: hidden;
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
        // justify-content: space-between;
    }

    .founder-text-box {
        display: flex;
        flex-direction: column;
        width: auto;
        padding: 0px 0px 30px 0px;
        /* justify-content: space-evenly; */
        /* width: 60vw; */
        /* padding-left: 9vw; */
    }

    .founder-full-img{
        width: 100%;
        height: 100%;
        padding-bottom: 30px;
    }

    .founder-subtitle{
        font-size: 18px;
        font-weight: 700px;
    }

    .founder-desc{
        font-size: 14px;
        font-weight: 500px;
    }

    .founder-head{
        text-align: left;
        font-size: 20px;
        font-weight: 700px;
    }

    .founder-data{
        align-items: flex-start;
        font-size: 14px;
        font-weight: 500px;
    }
}