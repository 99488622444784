
.topIcons{
    // position: relative;
    border-right: 0.5px solid #E5E5E5;
   &:hover{
    pointer-events: auto;
    cursor: pointer;
    background-color: rgba(24, 25, 29, 0.1);
    /* color: white; */
    -ms-transform: scale(1.02); /* IE 9 */
      -webkit-transform: scale(1.02); /* Safari 3-8 */
      transform: scale(1.02); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
   }
    .tooltip{
      visibility: hidden;
    }
   
  }

  .topIcons1{
    position: relative;
    border-left: 0.5px solid #E5E5E5;
   &:hover{
    pointer-events: auto;
    cursor: pointer;
    background-color: rgba(24, 25, 29, 0.1);
    /* color: white; */
    -ms-transform: scale(1.02); /* IE 9 */
      -webkit-transform: scale(1.02); /* Safari 3-8 */
      transform: scale(1.02); 
      transition: 0.3s all ease-in-out;
      opacity: 1 !important;
   }
    .tooltip{
      visibility: hidden;
    }
   
  }

  .grid{
    z-index: inherit;
    display: grid;
    grid-template-columns: 2fr 2.5fr .9fr .9fr .9fr .9fr .9fr .9fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    border: 1px solid #E5E5E5;
    & > div { 
      display: flex;
      align-items: center;
      justify-content: center;
        height: 67px;
       border-right: 0.5px solid #E5E5E5;
  
       &:nth-child(8){
        background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
        color: white;
        border-right: none;
        height: 100%;
        width: 100.3%;
        &:hover{
          background-color: #277EFB !important;
          color: white !important;
        }
      }
      &:nth-child(n+3){
       
        &:hover{
          pointer-events: auto;
          cursor: pointer;
          background-color: rgba(24, 25, 29, 0.1);
          /* color: white; */
          -ms-transform: scale(1.01); /* IE 9 */
            -webkit-transform: scale(1.01); /* Safari 3-8 */
            transform: scale(1.01); 
            transition: 0.2s all ease-in-out;
            opacity: 1 !important;
            color: #16171A;
        }
      }
    }
    
    
  }
  .gridNoBorder{
    z-index: inherit;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    & > div { 
      display: flex;
      align-items: center;
        justify-content: center;
        height: 67px;
       border: 0.5px solid rgba(229, 229, 229, 0.04);  
      &:nth-child(9){
        // background-color: #656565 !important;
        background-color: #16171A;
        color: white;
        opacity: 0.4;
      }
    }
    
  }
  
  
  .gridTwo{
    z-index: inherit;
    display: grid;
    grid-template-columns: 1.1fr 9.9fr 1.1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    border-right: 0.5px solid #E5E5E5;
    & > div {
      display: flex;
      align-items: center;
        justify-content: center;
        height: 40px;
     }  
  }
  
  .gridTwo1div{
    color: white;
    display: flex;
    justify-content: space-around;
  }
  .gridTwo2div{
    color: white;
    display: flex;
    justify-content: space-around;
  }
  
  .gridThree{
    z-index: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height:auto;
    padding:0px;
    grid-gap: 0ch;
    & > div { 
      display: flex;
      align-items: center;
        justify-content: center;
        height: 67px;
       border: 0.5px solid rgba(229, 229, 229, 0.04);  
      &:nth-child(8){
        // background-color: #656565 !important;
        background-color: #16171A;
        color: white;
        opacity: 0.4;
      }
    }
    
  }

  .searchBox {
    // width: 100%;
    height: 65px;
    // background: #FFFFFF;
    // border: 0.5px solid #E7E7E7;
    // border-radius: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
  
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    
    color:  #16171A;
  }
  
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.002em;
  
  color: #16171A;
  
  opacity: 0.25;
  }

  .selectednav{
    font-weight: 700;
    // border-bottom: 2px solid #16171A;
    border-width: 3px;
    color: #16171A;
    font-size: 15px;
  }
  .nonselectednav{
    // font-weight: 700;
    // border-bottom: 2px solid #16171A;
    border-width: 3px;
    color: #16171A;
    font-size:15px;
  }
     
 .cryptoimage{
  width: 100vw;
  display: flex;
  align-items: center;
  padding-top: 15px;
  // padding-left: 75px;
  // justify-content: center;
  // border-bottom: 0.5px solid #EBEBEB;
  // display: block;
  padding-bottom: 18px

 }
 .cryptoimage1{
  width: 100%;
  display: flex;
  justify-content: center;
  &:hover{
    .cryptoimage2{
      transform: scale(1.1);
      transition: all ease 0.3s;
    }
 }
 }
  @media only screen and (min-width: 0px) and (max-width: 900px){
    .mobilecards{
      display: block;
      width:100% ;
      height: 500px;
      border: 1px solid red;
    }
    .gridTwo{
      // display: none;
      border-bottom:0.5px solid #E5E5E5;
      // height: 0px;
      .topIcons{
        padding: 0 15px;
        
      }
      .topIcons1{
        padding: 0 15px;
      }
    }
    .about_bgclass__5O2cK{
      display: none;
    }
    .grid{
      display: none;
    }
    .cryptoimage{
      padding-top: "23px";
      // padding-left: "75px";
      // display: block;

    
     }
    

  }

  .mob-nav1{
    display: flex;
    border-bottom: 0.5px solid #EBEBEB;
    border-bottom: 5px solid #277EFB ;
    background: #fff;
    height: 76px;
    border-left-width: 0;
    border-right-width: 0;
    border-image: linear-gradient(90deg,#277EFB ,#B580F2) 1 stretch;
   }

  #navMenu{
    display: inline-block;
    display:grid;
    height:max-content;
    gap:4px;
    position: absolute;
    // top: 3%;
    // margin-top: 25px;
    left: 5%;
  }
  // .navbar-collapse-icon{
  //   font-size: 25px;
  //   width: 25px;
  //   height: 3px;
  //   background-color: #16171A;
  // }

  ////
  
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  right: 0;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:#000;
  stroke-width:5.5;
  stroke-linecap:round;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

  /// 

  @media only screen and (min-width: 900px){
    .cryptoimage{
      display: none;
    }
   .mob-nav1{
    display: none;
   }
  
  }

  @media screen and (min-width:900px) and (max-width: 1250px){
    .selectednav, .reg-nav{
      font-size: 12px;
    }

    .nonselectednav{
      font-size: 11px;
    }

    .cryptoimage1{
      padding-left: 30px !important;
    }

  }

  @media screen and (min-width:0px) and (max-width: 900px){

    .xcv{
      animation-delay: 0s;
      animation-play-state: running;
      // animation-iteration-count: 1;
      animation-duration: 2s;
      animation-name: supportanimationz;
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0;
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 0;
      position: fixed;
      top: 80px;
      left: 0;
      height: 85vh !important;
      z-index: 1;
      width: 100%;
      overflow: scroll;
      transform: translate(0%, -122%);
      p {
        display: block !important;
        text-align: center;
        margin-bottom: 1rem !important;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #16171A;
        margin-top: 0.5rem !important;
        justify-content: center;
    }
    ;
     
    padding-left: 20px !important;
    padding-right: 20px !important;
    transform: translate(0%, -114%);
    transition: all 2s;
    }

    // @keyframes supportanimationz {
    //   from {
    //     transform: translate(0%, 0%);
  
    //   }
    //   to {
      
    //     transform: translate(0%, -122%);
    //   }
    //  }
  
  .big-box0 {
    display: block !important;
    padding-left: 20px !important;
    padding-right: 20xp !important;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    // padding-top: 38px;
    position: fixed;
    top: 76px !important;
    left: 0;
    height: 85vh !important;
    overflow: scroll;
    padding-bottom: 2rem !important;
    z-index: 17;
    width: 100%;
 
    .logoheader{
      padding: 0px 0vw !important;
      border-right: 0px solid #E5E5E5 !important;
      display: flex !important;
      align-items: center !important;
      padding-right: 0px !important;
      justify-content: center !important;
      border-right: 1px solid #E5E5E5 !important;
      height: 8rem;
      padding-right: 20px !important;
      // margin-right: 4vw;
      &:hover{
        img{
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
    .boxinside{
      
  // display: flex;
  // align-items: center;
  // justify-content: center;
  
  background: #FFFFFF;

  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    height: 32px;
  }
  .boxinsideinner{
    display: flex;
  align-items: center;
  justify-content: center;
  width: 55px !important;
  height: 55px !important;
  border: 1px solid #E5E5E5 !important;
  border-radius: 5px !important;
  }
  &:hover{
    // transform: translateY(1) !important;
  //   p{
  //     display: flex;
  //     font-style: normal;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 15px;
  // display: flex;
  // align-items: center;
  
  // text-align: center;
  // width: 119.57px;
  // justify-content: center;
  // color: #16171A;margin-top: 0.6rem !important;
  //   }
  }
  
    }
    p{
      display: block !important;
      text-align: center;
      margin-bottom: 1rem !important;
      font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;

color: #16171A;
margin-top: 0.5rem !important;
justify-content: center;

    }
  }

}