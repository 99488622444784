.landingPage {
  display: flex;
  flex-direction: column;
  .pageWrap {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .contactMaster{
      height: 100%;
      width: 100%;
      color: #16171A;
      overflow: hidden;
      .titlesection{
        background-image: url('../../../static/images/collaborate.svg');
        background-size: cover;
        height:25rem;
        background: #16171A;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .title-box1{
          font-weight: 800;
          font-size: 45px;
          padding-bottom: 20px;
          color: #FFFFFF;
          width: 590px;
        }
        .input-box1{
          width: 590px;
          font-weight: 500;
          font-size: 13px;
          line-height: 30px;
          display: flex;
          padding: 20px;
          background-color: #FFFFFF;
          height: 55px;
          // width: 450px;
          border-radius: 35px;
          .searchicon{
            height: 15px;
            width: 25px;
            // padding-right: px;
          }
          .verticals{
            font-weight: 700;
            font-size: 20px;
            border: none;
            height: 15px;
            width: calc(100% - 55px);
            display: flex;
            flex-direction: column;
            // justify-content: center;
          }

        }
      }
      .querysection{
        height: 31rem;
        padding: 15px 0px 15px 0px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        width:72vw;
        margin: auto;
        flex-wrap: wrap;
      }
      .eachcard{
        cursor: pointer;
        height: calc(45% - 15px);
        margin: 10px 0px 0px 1.5%;
        width: 30.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 30px;
        border-top: 0.5px solid #EFEFEF;
        border-left: 15px solid  #16171A;
        border-right: 0.5px solid #EFEFEF;
        border-bottom: 0.5px solid #EFEFEF;
        border-radius: 15px;
      }
      .eachcard:hover{
        background-color: #f5f5f5;
        transform: scale(1.1);
        transition: .6s ease;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }

      .eachcard1{
        cursor: pointer;
        height: calc(45% - 15px);
        margin: 10px 0px 0px 0px;
        width: 30.33%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 30px;
        border-top: 0.5px solid #EFEFEF;
        border-left: 15px solid  #16171A;
        border-right: 0.5px solid #EFEFEF;
        border-bottom: 0.5px solid #EFEFEF;
        border-radius: 15px;
      }
      .eachcard1:hover{
        background-color: #f5f5f5;
        transform: scale(1.1);
        transition: .6s ease;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }


      .queryTitle{
        font-weight: 700;
        font-size: 20px;
        padding-bottom: 15px;
      }
      .queryDesc{
        font-weight: 300;
        font-size: 12px;
        line-height: 27px;
      }
    }
  }
}

p{
  margin: 0 !important;
}
