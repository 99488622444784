.old-nav-menu-container {
    width: 100vw;

    .big-box1 {
        padding: 35px;
        background: #ffffff;
        border: 0.5px solid #e7e7e7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        position: fixed;
        top: 109px;
        right: calc(11vw + 400px);
        z-index: 17;
      }
      
      .big-box2 {
        padding: 35px;
        background: #ffffff;
        border: 0.5px solid #e7e7e7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        position: fixed;
        top: 109px;
        right: calc(11vw + 420px);
        z-index: 17;
      }
      
      .big-box3 {
        padding: 35px;
        background: #ffffff;
        border: 0.5px solid #e7e7e7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        position: fixed;
        right: calc(6vw);
        top: 109px;
        z-index: 17;
        display: flex;
        justify-content: space-between;
      }
      
      .menus-left{
          width: fit-content;
      }
      
      .menus-right{
          width: fit-content;
          margin-left: 40px;
          border-left: 1px solid #E7E7E7;
          padding-left: 40px;
      }
      
      .big-box4 {
        padding: 35px;
        background: #ffffff;
        border: 0.5px solid #e7e7e7;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
        position: fixed;
        top: 109px;
        right: calc(35vw);
        z-index: 17;
      }
      
      .menu-title{
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #16171A;
      }
      
      .menu-desc{
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #16171A;
      }
      
      .each-menu{
          cursor: pointer;
          margin-top: 10px;
          padding: 20px;
          display: flex;
          &:hover{
              background-color: #e7e7e7;
              border-radius: 15px;
          }
      }
      
      .menus{
          width: fit-content;
          cursor: pointer;
          margin-top: 10px;
          padding: 20px;
          display: flex;
          &:hover{
              background-color: #e7e7e7;
              border-radius: 15px;
          }
      }
      
      .menu-img-box{
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 15px;
      }
  }
  
  