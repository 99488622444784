@import "../../../static/scss/mixin.scss";


// .templateSearch{
//     width: 100%;
//     background: #FFFFFF;
//     border-bottom: 0.5px solid #E5E5E5;
//     border-width: 0px 0px 0px 0px;
//     height: 80px;
//     padding:0px 80px;
// }



// .mainSection{
//     background: #FFFFFF;
//     border-top: 0.5px solid #E5E5E5;
//     // border-bottom: 0.5px solid #E5E5E5;
//     border-radius: 0px;
//     // height: 219px;
//     display: grid;
//     grid-template-columns: 4.2fr 5fr;

//     .textSection{
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         // border-right: 0.5px solid #E5E5E5;
//         padding: 40px 80px;
//         .textHeader{
//             font-family: 'Montserrat';
//             font-style: normal;
//             font-weight: 800;
//             font-size: 25px;
//             line-height: 45px;
//             /* or 180% */
//             display: flex;
//             align-items: center;
           
//             color: #16171A;
//             padding-left: 10px;
//         }
//         .textDesc{
//             padding-top: 25px;
//             font-family: 'Montserrat';
//             font-style: normal;
//             font-weight: 300;
//             font-size: 12px;
//             line-height: 30px;
//             /* or 250% */

//             display: flex;
//             align-items: center;

//             color: #16171A;
//         }
//         .goButton{
//             width: 32px;
//             height: 32px;
//             background: #F3F4F5;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             border-radius: 50%;
//             &:hover{
//                 cursor: pointer;
//                 transform: scale(1.2);
//                 transition: 0.2s all ease-in-out;
//             }
//         }
//     }
//     .scrollSection{
//         display: flex;
//         align-items: center;
//         // grid-row: auto;
//         // grid-template-columns: 1fr;
//         // overflow-x: scroll;
//         // width: 700px;
//         display: grid;
//         grid-gap: 16px;
//         padding: 16px;
//         grid-template-columns: repeat(auto-fill,minmax(288px,1fr));
//         grid-auto-flow: column;
//         grid-auto-columns: minmax(288px,1fr);
//         overflow-x: auto;

//         .scrollCards{
//             width: 288px !important;
//             height: 181px;
//             background: #FFFFFF;
//             border: 0.5px solid #E5E5E5;
//             border-radius: 15px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             &:hover{
//                 cursor: pointer;
//                 transform: scale(1.03);
//                 transition: 0.2s all ease-in-out;
//             }
//         }
//     }
// }


.template-master{
    background-color: #F5F5F5;
    height: calc(100vh);
    width: 100vw;
    color: #16171A;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 5rem;

    .section1{
        height: 60vh;
        width: 100vw;
        background-color: #16171A;
        
    }
    
    .full-box{
        height: 60vh;
        width:72vw;
        margin: auto;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
    }
    
    .section1-leftbox{
        width: 41vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        .mini-head{
            font-weight: 400;
            font-size: 32px;
            line-height: 39px;
            padding-bottom: 30px;
            color: #FFFFFF;
        }
        
        .main-head{
            font-weight: 800;
            font-size: 55px;
            line-height: 65px;
            color: #FFFFFF;
        }
        
        .start-btn{
            margin-top: 50px;
            height: 60px;
            width: 200px;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #FFFFFF;
            border-radius: 25px;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            background-color: #16171A;
            // overflow: hidden;
            // position: relative;
             @include btn-animate(#FFFFFF);
            cursor: pointer;
            &:hover{
                transform: scale(1.05);
                transition: ease .2s;
            }
        }
    }
    
    .section1-rightbox{
        margin-top: 10vh;
        width: 41vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: flex-end;
    
        .section1-main-img1{
            width: 35vw;
            // height: 50vh;
        }
    }
    
    .section2{
        height: 200vw;
        width: 100vw;
        background-color: #FFFFFF;
        // position: sticky;
        // top: 0;
        
        .section2-part1{
            height: 400px;
            width: 100vw;
            background: linear-gradient(180deg, #16171A 50%, #FFFFFF 50%);
        }
        .section2-part1-update{
            height: 150px;
            width: 100vw;
            background-color: #FFFFFF;
            border-bottom: 1px solid #F5F5F5;
            position: sticky;
            top: 0;
        }
        .section2-part2{
            height: calc(200vh - 400px);
            width: 100vw;
            background-color: #FFFFFF;
        }
        .section2-part2-update{
            height: calc(200vh - 151px);
            width: 100vw;
            background-color: #FFFFFF;
            position: sticky;
            top: 150px;
            border-bottom: 2px solid #F5F5F5;
            // transition: ease 1s;
        }
        // transform: scale(1.01);
        .section2-main-card{
            width:72vw;
            height: 400px;
            margin: auto;
            background-color: #FFFFFF;
            border: 2px solid #F5F5F5;
            border-radius: 25px;
            // padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    
        .section2-main-head{
            font-weight: 700;
            font-size: 35px;
            line-height: 43px;
            color: #16171A;
            text-align: center;
            padding-bottom: 50px;
        }
    
        .section2-all-pairs{
            width: calc(78vw - 50px);
            height: 190px;
            overflow-x: scroll;
            display: -webkit-inline-box;
        }
    
        // @keyframes left_to_right{
        //     from{
        //         margin-left: 91vw;
        //     }
        //     to{
        //         margin-left:9vw;
        //     }
        // }
    
        @keyframes fadeIn {
            // 0% { opacity: 0; }
            // 50% { opacity: 0.5; }
            // 100% { opacity: 1; }
            0%{opacity: 0;}
            25%{opacity: .25;}
            50%{opacity: .50;}
            75%{opacity: .75;}
            100%{opacity: 1;}
          }
    
          
    
        .update-all-pairs{
            width: 100vw;
            padding-left: 30px;
            padding-right: 10vw;
            height: 150px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            animation: fadeIn .7s linear;
        }
    
        .section2-each-pair{
            width: 14vw;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                padding-bottom: 30px;
                transition: ease .2s;
            }
        }
    
        .section2-each-img{
            height: 90px;
            width: 90px;
            margin-bottom: 10px;
        }
    
        .section2-each-title{
            font-weight: 600;
            font-size: 17px;
            line-height: 21px;
            text-align: center;
            color: #16171A;
        }
    
        .update-each-title{
            display: flex;
            font-weight: 600;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            color: #16171A;
        }
    
        .update-each-pair{
            // padding-top: 30px;
            width: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                padding-bottom: 10px;
                transition: ease .2s;
                .update-each-title{
                    display: flex !important;
                }
            }
        }
    
        .update-each-img{
            height: 40px;
            width: 40px;
            margin-bottom: 10px;
        }
    
        
    }
    
}

