.templateGrid{
    display: grid;
    height: 70vh;
    grid-template-columns: 2.7fr 7fr;
    .leftSection{
        padding: 40px 80px;
        border-right: 0.5px solid #EBEBEB ;
    }
    .rightSection{
       overflow-y: scroll;
        .eachSection{
            height: 60vh;
            padding: 45px 70px;
            border-bottom: 0.5px solid #EBEBEB;
            .title{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 800;
                font-size: 44px;
                line-height: 54px;
                display: flex;
                align-items: center;
                color: #16171A;
            }
        }
       
    }
}

.templateTitle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 45px;
    /* or 180% */

    display: flex;
    align-items: center;

    color: #16171A;
    padding-left: 13px;
}

.searchDiv{
    background: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    border-radius: 10px;
    height: 47px;
    width:100%;
    margin-top: 35px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.searchInput{
    width: 100%;
    border: none;
    padding-left: 6px;
    &::placeholder{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 35px;
        /* or 292% */

        display: flex;
        align-items: center;

        color: #16171A;

        opacity: 0.45;
    }
}

.templateMenuWrapper{
    padding-top: 20px;
    overflow-y: scroll;
    margin-left: -26px;
    // height: 70vh;
    // padding-bottom: 60px;
    .templateMenuItem{
        display: flex;
        align-items: center;
        padding: 26px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #212121;

       

        &:hover{
            background-color: #F5F5F5;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #212121;
            cursor: pointer;
        }
    }
}

.subMenu{
    padding-left: 66px;
    margin-top: -15px;
    & > div{
        &:nth-child(1){
            font-weight: 700;
        }
        padding: 10px 0px;
        &:hover{
            // background-color: #F5F5F5;
            font-weight: 700;
            font-size: 14px;
            // line-height: 17px;
            color: #212121;
            cursor: pointer;
        }
    }
}
