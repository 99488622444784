// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;600&display=swap");
@import "./static/scss/newConglomerate";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

#reve-chat-container-div{
  // top: 200px !important;
  z-index: 1 !important;
  height: 420px !important;
}

@media only screen and  (min-width: 0px) and (max-width: 7000px){
  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .row {
    width: 100vw !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

}



.row>*{
 padding: 0 !important;
 margin: 0 !important;
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #5F6163;
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

* {
  box-sizing: border-box;
  font-family: "Montserrat" !important;
  font-style: normal;
  outline: none;
  margin: 0;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}


html, body{min-height:100%; outline: none;}
body{min-height:100vh;
    overflow: hidden;
    overscroll-behavior-x: none;
    scroll-behavior: smooth !important;
    // outline: none;
    ::-webkit-scrollbar {
        display: none;
      }
}
#root{
    height:100vh;
    outline: none;
}

body #root * {
    font-family: "Montserrat" !important;
    outline: none;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
}

.grid-cap{
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 0.2fr 1fr 6fr;
}

.grid-cap-collapsed{
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 0.2fr 6fr;
}


.grid-cap{
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 0.2fr 1fr 6fr;
}

.grid-cap-collapsed{
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 0.2fr 6fr;
}


.overlayClose{
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 5;
  }

  .overlayClosetemp{
    bottom: 0;
    height: 100%;
    left: 0;
    overflow: hidden !important;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 5;
    }

  .collapsedSideBar{
    padding: 120px 0px 0px 10px;
    width: calc(100vw - 20px);
    // height: 99vh;
    background: transparent;
    // display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    height: 100vh;
    padding-bottom: 2rem;
    // text-align: center;
    animation-delay: 0s;
    animation-play-state: running;
   
    animation-duration: 2s;
    animation-name: supportanimationw;
  }
  @keyframes supportanimationw {
    from {
     transform: translate(-100%, 0);
    }
    to {
     transform: translate(0%, 0);
    }
   }
  .mob-nav-title{
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 80px;
    color: #16171A;
    cursor: pointer;
    margin-bottom: 1rem;
    padding-left: 10px ;
    &:hover{
        font-weight: 600;
        transform: scale(1.1);
        transition: ease-out .3s;
    }
  }

  .mob-nav-title-active{
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 80px;
    color: #16171A;
    cursor: pointer;
  
    background: rgba(24, 37, 66, 0.05);
    padding-left: 10px ;
    border-radius: 10px;
    margin-bottom: 1rem;
    &:hover{
        font-weight: 600;
        // transform: scale(1.1);
        transition: ease-out .3s;
    }
  }

  .mob-nav-title1{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 2rem;
   
  }

  .mob-login-btn{
    margin: 10px 0px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background: #FFFFFF;

    border-radius: 25px;
    color: #ffff;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background: linear-gradient(275.12deg, #277EFB 7.36%, #B580F2 140.3%);
  }

  .mob-login-btn::after {
    background: #16171A;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .mob-login-btn:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }
  

  .mob-launch-btn{
    margin: 10px 0px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(24, 37, 66, 0.05);
    // border: 0.5px solid #277EFB;
    border-radius: 45px;
    color: #16171A;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  .mob-launch-btn::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .mob-launch-btn:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }
  
  // img{
  //   pointer-events: none;
  // }

  // .alice-carousel__stage-item{
  //   margin-right: 20px !important;
  // }

  @media only screen and (max-device-width: 900px), screen and (max-width: 900px){
    #reve-chat-container-div {
      display: none !important;
  }

  .alice-carousel__stage-item{
    margin-right: 20px !important;
    display: inline-table !important;
  }

}


