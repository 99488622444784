.productMaster{
    // margin-top: 109px;
    display: flex;
    // height: calc(100vh - 120px);
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    color: #16171A;
}


.productSideBar{
    width: 20%;
    height: 100%;
    padding: 20px 0 20px 45px;
    border-right: 0.5px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.eachMenu{
    display: flex;
    padding: 10px 10px 10px 0;
    cursor: pointer;
    &:hover{
        width: 95%;
        transform: scale(1.1);
        transform: ease 1s;
    }
}

.menutext{
    margin-left: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #16171A;
    align-items: center;
    display: flex;
}

.menutext1{
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #16171A;
}

.productContentBox{
    overflow-y: scroll;
    width: 75%;
    margin-right: 5%;
    padding-left: 70px;
    // margin-right: 9.105%;
    height: 100%;
}

.each-type-box{
    margin: 30px 0 100px 0;
}

.each-type-box1{
    margin: 100px 0;
}

.type-title{
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #16171A;
    background-color: rgba(237, 237, 237, 0.37);
    padding: 10px;
}

.type-info{
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #16171A;
    // padding-bottom: 40px;
}

.type-bold{
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
        font-size: 17px;
        transition: ease 0.3s;
    }
}

.all-cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.check1-text{
    display: none;
}

.clickarrow-img{
    height: 20px;
    width: 20px;
}

.zoom1{
    background: #f3f4f5;
    border: 0;
    border-radius: 30px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
    display: inline-block;
    height: 40px;
    overflow: hidden;
    transition: all .3s ease-out;
    width: 40px;
}

.icon1-img{
    height: 40px;
    width: 40px;
}


.one-box{
    padding: 40px;
    margin-right: 1.33%;
    width: 47%;
    margin-top: 50px;
    background: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    border-radius: 15px;
    // height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // cursor: pointer;
    &:hover{
        .check1-text{
            color: #16171A;
            display: inline-flex;
            margin-left: 9px;
            margin-top: 5px;
            font-size: 15px;
            font-weight: 700;
        }
        .clickarrow-img{
            margin-left: 0.2rem;
            margin-top: 0.3rem;
            width: 20px;
        }
        .zoom1{
            background: #fffbf0;
            height: 45px;
            text-align: center;
            width: 120px;
        }
        .icon1-img{
            // margin-top: 0.3rem;
            -webkit-transform: scale(1.2);
            transform: scale(1.5);
            transition: all .3s ease-in-out;        
        }
        // box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        box-shadow: rgba(0, 0, 0, 0.15) 5px 0px 0px 5px;
        // box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%), inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
    }
}

.p-card-title{
    font-weight: 800;
    font-size: 30px;
    line-height: 44px;
    color: #16171A;
    padding-bottom: 28px;
}

.card-desc{
    // margin-top: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 30px;
    color: #16171A;
    padding-bottom: 30px;
}

.card-image-box{
    // padding-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

}

.mobileView{
    display: none;
}


@media screen and (max-width: 900px) {
    .productSideBar{
        display: none;
    }

    .productContentBox{
        display: none;
    }

    .mobileView{
        overflow:  scroll;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .each-mob-menu{
        width: 100vw;
        border-bottom: 1px solid #E5E5E5;
    }

    .each-mob-menu-head{
        display: flex;
        padding: 40px 40px;
        justify-content: space-between;
        .each-mob-title{
            display: flex;
            .each-mob-img1{
                height: 50px;
                width: 50px;
            }
            .each-mob-head{
                height: 50px;
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 24px;
                margin-left: 20px;
            }
        }
        .each-mob-imgbox{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
        }
    }


    .each-mob-menu-body{
        padding-left: 40px;
        .type-title {
            font-weight: 800;
            font-size: 25px;
            color: #16171A;
        }
        .type-info{
            font-weight: 300;
            font-size: 13px;
            display: flex;
            align-items: center;
            color: #16171A;
        }
        .all-cards {
            width: 90%;
            height: 280px;
            display: -webkit-inline-box;
            margin-top: 40px;
            overflow-x: scroll;
            flex-wrap: nowrap;
        }

        .one-box {
            margin-top: 0px;
            margin-right: 20px;
            width: 90%;
            height: 277px;
            justify-content: center;
        }

        .p-card-title{
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            color: #16171A;
        }

        .card-desc{
            font-weight: 300;
            font-size: 10px;
            line-height: 25px;
            color: #16171A;
        }

        
    }
}


.ps-box{
    padding: 40px 20px 0px 20px;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    z-index: 1;
}


.ps-title-box{
    display: flex;
    justify-content: space-between;
}

.ps-desc-box{
    padding: 20px 0px;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #16171A;
}

.ps-img{
    height: 50px;
    width: 50px;
    margin-right: 10px;
}

.ps-title{
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
}

.ps-left-box{
    display: flex;
}

.ps-right-box{
    background: #FFFFFF;
    border: 0.5px solid #16171A;
    color: #16171A;
    border-radius: 25px;
    height: 40px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 32px;
    overflow: hidden;
    position: relative;
}


.ps-right-box::after {
    cursor: pointer;
    background: #16171A;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all .55s cubic-bezier(.19,1,.22,1);
    width: 50px;
    z-index: -10;
  }
  
  .ps-right-box:hover::after{
    left: 120%;
    transition: all .55s cubic-bezier(.19,1,.22,1);
    z-index: 50;
  }